<template>
  <div id="detail-jclq">
    <van-sticky v-if="type === 2" :offset-top="90">
      <div class="info-sticky">
        <div class="info">
          <van-row type="flex" justify="space-between">
            <van-col
              style="color: rgb(46, 91, 253)"
              @click="handleToUSerDetail(project.user_id)"
              >用户昵称：{{ project.user.nickname
              }}<span v-if="project.user.remark"
                >（{{ project.user.remark }}）</span
              ></van-col
            >
            <van-col></van-col>
          </van-row>
          <van-row type="flex" justify="space-between">
            <van-col>{{ project.project_id }}</van-col>
            <van-col>{{ project.state | formatProjectState }}</van-col>
          </van-row>
          <van-row type="flex" justify="space-between">
            <van-col>
              <div>
                {{ project.type | formatProjectType }}
                <span v-for="(o, i) in project.pass_type.split(',')" :key="i">
                  {{ o | formatProjectPassType }}
                </span>
              </div>
            </van-col>
            <van-col>
              <div>{{ project.bet_multi }}倍 ¥{{ project.bet_money }}元</div>
            </van-col>
            <van-col v-if="project.state >= 3">
              <div class="bonus">中奖：¥{{ project.bonus_money }}元</div>
            </van-col>
          </van-row>
          <van-row type="flex" justify="space-between">
            <van-col>
              下单时间：{{ project.created_at | formatDateTime }}
            </van-col>
            <van-col>
              <span v-if="project.state >= 0">出票时间：</span>
              <span v-else>撤单时间：</span>
              {{ project.handled_at | formatDateTime }}
            </van-col>
          </van-row>
          <van-row v-if="project.state >= 3 && bonus_sub_tax > 0">
            <div class="bonus">
              奖金：¥{{ project.bonus_money }}元 - ¥{{ project.tax }}元(税) =
              ¥{{ bonus_sub_tax }}元
            </div>
          </van-row>
        </div>
      </div>
    </van-sticky>

    <ul class="order-list mt10 pb50" v-if="type === 2">
      <li class="card" v-for="(item, key) in matches" :key="key">
        <div class="top">
          <span>(客){{ item.away_team }}</span>
          <span class="ml10 mr10">vs</span>
          <span>{{ item.home_team }}(主)</span>
        </div>
        <div class="main">
          <div class="l">
            <div>
              {{ item.number.substr(8, 1) | formatWeekday }}
            </div>
            <div>
              {{ item.number.substr(9, 3) }}
            </div>
          </div>
          <div class="r">
            <table>
              <tr>
                <td>玩法</td>
                <td>选项</td>
                <td>赛果</td>
              </tr>
              <tr v-if="options[item.number].b_sf_f.length > 0">
                <td class="wid-20">胜负</td>
                <td>
                  <span
                    v-for="(val, k) in options[item.number].b_sf_f"
                    :key="k"
                    :class="
                      handleSfResult(item.bt_home_point, item.bt_away_point) ===
                        val && item.state === 13
                        ? 'red'
                        : ''
                    "
                  >
                    {{ val | formatSportsSf }}
                    <span v-if="item.state === -3"> （1） </span>
                    <span v-else
                      >（
                      <label
                        :class="
                          project.state === 1 || project.state === 0
                            ? 'underLine'
                            : ''
                        "
                        @click="
                          handleEditRate(
                            item,
                            val,
                            resultRates[item.number].b_sf_f[val],
                            'b_sf_f'
                          )
                        "
                        >{{ resultRates[item.number].b_sf_f[val] }}</label
                      >
                      ）</span
                    >
                  </span>
                </td>
                <td class="wid-20">
                  <span v-if="item.state === 13">{{
                    handleSfResult(item.bt_home_point, item.bt_away_point)
                      | formatSportsSf
                  }}</span>
                  <span v-if="item.state === -3"> 赛事无效 </span>
                </td>
              </tr>
              <tr v-if="options[item.number].b_rfsf_f.length > 0">
                <td class="wid-20">
                  让分胜负（<label
                    :class="
                      project.state === 1 || project.state === 0
                        ? 'underLine'
                        : ''
                    "
                    @click="handleEditPoint(item, 1)"
                    >{{ item.bt_let_point_multi }}</label
                  >）
                </td>
                <td>
                  <span
                    v-for="(val, k) in options[item.number].b_rfsf_f"
                    :key="k"
                    :class="
                      handleRfsfResult(
                        item.bt_home_point,
                        item.bt_away_point,
                        item.bt_let_point_multi
                      ) === val && item.state === 13
                        ? 'red'
                        : ''
                    "
                  >
                    {{ val | formatSportsRfsf }}
                    <span v-if="item.state === -3">（1）</span
                    ><span v-else>
                      （
                      <label
                        :class="
                          project.state === 1 || project.state === 0
                            ? 'underLine'
                            : ''
                        "
                        @click="
                          handleEditRate(
                            item,
                            val,
                            resultRates[item.number].b_rfsf_f[val],
                            'b_rfsf_f'
                          )
                        "
                        >{{ resultRates[item.number].b_rfsf_f[val] }}</label
                      >
                      ）</span
                    >
                  </span>
                </td>
                <td class="wid-20">
                  <span v-if="item.state === 13">
                    {{
                      handleRfsfResult(
                        item.bt_home_point,
                        item.bt_away_point,
                        item.bt_let_point_multi
                      ) | formatSportsRfsf
                    }}</span
                  >
                  <span v-if="item.state === -3"> 赛事无效 </span>
                </td>
              </tr>
              <tr v-if="options[item.number].b_sfc_f.length > 0">
                <td class="wid-20">胜分差</td>
                <td>
                  <span
                    v-for="(val, k) in options[item.number].b_sfc_f"
                    :key="k"
                    :class="
                      handleSfcResult(
                        item.bt_home_point,
                        item.bt_away_point
                      ) === val && item.state === 13
                        ? 'red'
                        : ''
                    "
                  >
                    {{ val | formatSportsSfc }}
                    <span v-if="item.state === -3"> （1） </span
                    ><span v-else
                      >（
                      <label
                        :class="
                          project.state === 1 || project.state === 0
                            ? 'underLine'
                            : ''
                        "
                        @click="
                          handleEditRate(
                            item,
                            val,
                            resultRates[item.number].b_sfc_f[val],
                            'b_sfc_f'
                          )
                        "
                        >{{ resultRates[item.number].b_sfc_f[val] }}</label
                      >
                      ）</span
                    >
                  </span>
                </td>
                <td class="wid-20">
                  <span v-if="item.state === 13">{{
                    handleSfcResult(item.bt_home_point, item.bt_away_point)
                      | formatSportsSfc
                  }}</span>
                  <span v-if="item.state === -3"> 赛事无效 </span>
                </td>
              </tr>
              <tr v-if="options[item.number].b_dxf_f.length > 0">
                <td class="wid-20">
                  大小分（
                  <label
                    :class="
                      project.state === 1 || project.state === 0
                        ? 'underLine'
                        : ''
                    "
                    @click="handleEditPoint(item, 2)"
                    >{{ item.bt_base_point_multi }}</label
                  >
                  ）
                </td>
                <td>
                  <span
                    v-for="(val, k) in options[item.number].b_dxf_f"
                    :key="k"
                    :class="
                      handleDxfResult(
                        item.bt_home_point,
                        item.bt_away_point,
                        item.bt_base_point_multi
                      ) === Number(val) && item.state === 13
                        ? 'red'
                        : ''
                    "
                  >
                    {{ val | formatSportsDxf }}
                    <span v-if="item.state === -3"> （1） </span
                    ><span v-else
                      >（
                      <label
                        :class="
                          project.state === 1 || project.state === 0
                            ? 'underLine'
                            : ''
                        "
                        @click="
                          handleEditRate(
                            item,
                            val,
                            resultRates[item.number].b_dxf_f[val],
                            'b_dxf_f'
                          )
                        "
                        >{{ resultRates[item.number].b_dxf_f[val] }}</label
                      >
                      ）</span
                    >
                  </span>
                </td>
                <td class="wid-20">
                  <span v-if="item.state === 13">
                    {{
                      handleDxfResult(
                        item.bt_home_point,
                        item.bt_away_point,
                        item.bt_base_point_multi
                      ) | formatSportsDxf
                    }}</span
                  >
                  <span v-if="item.state === -3"> 赛事无效 </span>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </li>
    </ul>

    <div v-if="childOptions.length > 0 && type === 1" class="child mt10">
      <p class="red tac" style="background: #fff">
        总方案金额：{{ project.bet_money }}元
      </p>
      <div class="title">
        <van-row
          type="flex"
          style="height: 44px; background: #fff"
          justify="space-between"
          align="center"
        >
          <van-col>
            <span class="ml16"
              >实票（共{{ tickets.length }}页{{ ticketCount }}张）</span
            >
          </van-col>
          <van-col span="12" class="tar">
            <span class="mr5"
              >订单尾号：{{ project.project_id.slice(-6) }}
            </span>
          </van-col>
        </van-row>
      </div>

      <div class="body pb50">
        <van-swipe
          ref="swipe"
          @change="handleChange"
          :touchable="false"
          :show-indicators="false"
        >
          <van-swipe-item v-for="(item, key) in tickets" :key="key">
            <div class="child-content">
              <div class="child-tag">
                注意：该投注单需要扫描
                <span>{{ item.count }}</span
                >次<span>{{ item.bet_multi }} </span>倍
              </div>

              <div class="child-type ft14 mb10">
                玩法：{{ item.play_type | formatProjectPayType }}
              </div>

              <ul class="order-list">
                <li
                  class="card"
                  v-for="(item1, key1) in Object.keys(childOptions[key]).sort()"
                  :key="key1"
                  :class="nowDate !== item1.substr(0, 8) ? 'red' : ''"
                >
                  <div>
                    <span class="order-week">
                      {{ item1.substr(8, 1) | formatWeekday }}
                      {{ item1.substr(9, 3) }}
                    </span>
                    <div class="order-result">
                      <span
                        v-for="(val, k) in childOptions[key][item1].b_sf_f"
                        :key="k"
                      >
                        {{ val | formatSportsSf }}（{{
                          resultRates[item1]["b_sf_f"][val]
                        }}）
                        <label>,</label>
                      </span>
                      <span
                        v-for="(val, k) in childOptions[key][item1].b_rfsf_f"
                        :key="k"
                      >
                        {{ val | formatSportsRfsf }}（{{
                          resultRates[item1]["b_rfsf_f"][val]
                        }}）
                        <label>,</label>
                      </span>
                      <span
                        v-for="(val, k) in childOptions[key][item1].b_sfc_f"
                        :key="k"
                      >
                        {{ val | formatSportsSfc }}（{{
                          resultRates[item1]["b_sfc_f"][val]
                        }}）
                        <label>,</label>
                      </span>
                      <span
                        v-for="(val, k) in childOptions[key][item1].b_dxf_f"
                        :key="k"
                      >
                        {{ val | formatSportsDxf }}（{{
                          resultRates[item1]["b_dxf_f"][val]
                        }}）
                        <label>,</label>
                      </span>
                    </div>
                  </div>
                </li>
              </ul>

              <div class="ml40 mr40 ft12 gray0">
                <van-row class="mt10">
                  <van-col span="12">
                    串关：<span
                      v-for="(o, i) in item.pass_type.split(',')"
                      :key="i"
                    >
                      {{ o | formatProjectPassType }}
                    </span>
                  </van-col>
                  <van-col span="12"
                    >倍数：<span>{{ item.bet_multi }}</span></van-col
                  >
                </van-row>
                <van-row class="mt20">
                  <van-col span="12"
                    >金额：<span>{{ item.bet_money }}元</span></van-col
                  >
                  <van-col span="12">
                    最大奖金：¥<span>{{ max_prize.toFixed(2) }}</span>
                  </van-col>
                </van-row>
              </div>
            </div>
          </van-swipe-item>
        </van-swipe>

        <div class="nav">
          <van-icon
            class="arrow-left"
            v-show="childOptions.length > 1"
            name="arrow-left"
            @click="handleLast"
            style="font-weight: bold"
            color="#1989fa"
            size="34"
          />
          <!-- <van-button type="primary" size="small"
            >第{{ childIndex + 1 }}页 点击打印<span v-show="tickets.length > 1"
              >{{ tickets[childIndex].count }}张票</span
            >
          </van-button> -->
          <van-icon
            class="arrow-right"
            v-show="childOptions.length > 1"
            name="arrow"
            @click="handleNext"
            style="font-weight: bold"
            color="#1989fa"
            size="34"
          />
        </div>
      </div>
    </div>
    <!-- <p-tabbar type="jclq"></p-tabbar> -->

    <van-dialog
      v-model="editVisible"
      @confirm="handleEditSubmit"
      :title="editTitle"
      show-cancel-button
    >
      <van-field
        type="number"
        v-model="editText"
        right-icon="edit"
        placeholder="请输入"
      ></van-field>
    </van-dialog>
  </div>
</template>

<script>
// import PTabbar from "@/components/PTabbar";
import dayjs from "dayjs";
import { Decimal } from "decimal.js";
import project from "@/api/project";
export default {
  name: "detail-jclq",
  props: [
    "project",
    "matches",
    "options",
    "rates",
    "resultRates",
    "childOptions",
    "tickets",
    "type",
    "bonus_sub_tax",
  ],
  data() {
    return {
      editType: 1,
      editItem: {},
      editVisible: false,
      editTitle: "修改",
      editText: 0,
      match: {},
      childIndex: 0,
      ticketCount: 0,
      nowDate: dayjs(new Date()).format("YYYYMMDD"),
      max_prize: 0,
    };
  },
  created() {
    this.matches.forEach((o) => {
      this.match[o.number] = {
        away_team: o.away_team,
        home_team: o.home_team,
        bt_base_point_multi: o.bt_base_point_multi,
        bt_let_point_multi: o.bt_let_point_multi,
      };
    });
    this.handleCalcCount();
    this.handleJudgeLast();
    this.handleCalcMaxPrize();
  },
  // components: {
  //   PTabbar
  // },
  methods: {
    handleToUSerDetail(val) {
      this.$router.push({
        name: "account-book-customer-detail",
        query: {
          user_id: val,
        },
      });
    },
    handleEditPoint(val, n) {
      if (this.project.state !== 1) {
        return false;
      }
      if (dayjs().isAfter(dayjs(this.project.expire_time))) {
        this.$toast("截止时间已过");
        return false;
      }
      let format = {
        type: val.type,
        bet_match: val.number,
        bet_point: val.bt_let_point_multi,
        bt_base_point_multi: val.bt_base_point_multi,
      };

      if (n === 1) {
        this.editTitle = "修改让球数";
        this.editText = val.bt_let_point_multi;
      } else if (n === 2) {
        this.editTitle = "修改预设总分";
        this.editText = val.bt_base_point_multi;
      }
      this.editType = 2;
      this.editItem = format;
      this.editVisible = true;
    },
    handleEditRate(val, v, v1, v2) {
      if (this.project.state !== 1 && this.project.state !== 0) {
        return false;
      }
      if (dayjs().isAfter(dayjs(this.project.expire_time))) {
        this.$toast("截止时间已过");
        return false;
      }
      let format = {
        type: val.type,
        play_type: v2,
        bet_match: val.number,
        bet_code: v,
        bet_rate: v1,
        pk: val.pk,
      };

      this.editType = 1;
      this.editItem = format;
      this.editTitle = "修改赔率";
      this.editVisible = true;
      this.editText = v1;
    },
    handleEditSubmit() {
      this.$toast("暂无权限");
      if (this.project.state !== -2) {
        return false;
      }
      if (this.editType === 1) {
        project
          .editSportsRate(this.$route.query.pk, {
            type: this.editItem.type,
            play_type: this.editItem.play_type,
            bet_match: this.editItem.bet_match,
            bet_code: this.editItem.bet_code,
            bet_rate: this.editText,
          })
          .then(() => {
            this.$emit("handleInit");
          })
          .catch((res) => {
            this.$toast(res.message);
            this.$emit("handleInit");
          });
      } else if (this.editType === 2) {
        project
          .editSportsPoint(this.$route.query.pk, {
            type: this.editItem.type,
            // play_type: this.editItem.play_type,
            bet_match: this.editItem.bet_match,
            bet_code: this.editItem.bet_code,
            bet_point:
              this.editTitle === "修改让球数"
                ? this.editText
                : this.editItem.bet_point,
            bt_base_point_multi:
              this.editTitle === "修改预设总分"
                ? this.editText
                : this.editItem.bt_base_point_multi,
          })
          .then(() => {
            this.$emit("handleInit");
          })
          .catch((res) => {
            this.$toast(res.message);
            this.$emit("handleInit");
          });
      }
    },
    handleCalcCount() {
      let count = 0;
      this.tickets.forEach((res) => {
        count += res.count;
      });
      this.ticketCount = count;
    },
    handleChange(val) {
      this.childIndex = val;
      this.handleJudgeLast();
      this.handleCalcMaxPrize(val);
      this.handleScrollTo();
    },
    handleJudgeLast() {
      if (this.childIndex + 1 === this.tickets.length) {
        this.$emit("handleIsLast", true);
      } else {
        this.$emit("handleIsLast", false);
      }
    },
    handleScrollTo() {
      let order_list_height =
        document.getElementsByClassName("order-list")[0].clientHeight + 10;
      document.getElementsByClassName("item-detail")[0].scrollTop =
        order_list_height;
    },
    handleLast() {
      if (this.childIndex === 0) {
        this.$toast("当前第一张票");
        return;
      }
      this.$refs.swipe.prev();
    },
    handleNext() {
      if (this.childIndex + 1 === this.tickets.length) {
        this.$toast("当前最后一张票");
        return;
      }
      this.$refs.swipe.next();
    },
    handlePrint() {
      this.$emit("handlePrint", this.tickets[this.childIndex]);
    },
    handleSfResult(bt_home_point, bt_away_point) {
      return this.handleCompare(bt_home_point, bt_away_point);
    },

    handleRfsfResult(bt_home_point, bt_away_point, bt_let_point_multi) {
      return this.handleCompare(
        Number(bt_home_point) + Number(bt_let_point_multi),
        bt_away_point
      );
    },

    handleDxfResult(bt_home_point, bt_away_point, bt_base_point_multi) {
      return Number(bt_home_point) + Number(bt_away_point) >
        Number(bt_base_point_multi)
        ? 1
        : 0;
    },

    handleSfcResult(bt_home_point, bt_away_point) {
      let away = Number(bt_away_point);
      let home = Number(bt_home_point);
      let sfc = away - home;

      if (-5 <= sfc && sfc <= -1) {
        return "01";
      } else if (-10 <= sfc && sfc <= -6) {
        return "02";
      } else if (-15 <= sfc && sfc <= -11) {
        return "03";
      } else if (-20 <= sfc && sfc <= -16) {
        return "04";
      } else if (-25 <= sfc && sfc <= -21) {
        return "05";
      } else if (sfc <= -26) {
        return "06";
      } else if (sfc >= 1 && sfc <= 5) {
        return "07";
      } else if (sfc >= 6 && sfc <= 10) {
        return "08";
      } else if (sfc >= 11 && sfc <= 15) {
        return "09";
      } else if (sfc >= 16 && sfc <= 20) {
        return "10";
      } else if (sfc >= 21 && sfc <= 25) {
        return "11";
      } else if (sfc >= 26) {
        return "12";
      }
    },

    handleCompare(a, b) {
      return Number(a) - Number(b) > 0
        ? "3"
        : Number(a) - Number(b) === 0
        ? "1"
        : "0";
    },

    handleCalcMaxPrize(i = 0) {
      // 拆单每个场次只有有一种玩法 只有取最大值就可以
      // 最大赔率数组
      let self = this;
      let max_prize_array = [];
      this.max_prize = 0;

      Object.keys(this.childOptions[i]).forEach((o) => {
        let t = [];
        let obj = this.childOptions[i][o];

        Object.keys(obj).forEach((o1) => {
          if (obj[o1].length > 0) {
            t = obj[o1].map((o2, i2) => {
              return self.rates[o][o1][i2];
            });
          }
        });
        max_prize_array.push(Math.max(...t));
      });

      // 串关数
      let chuan = this.tickets[i].pass_type.split(",").map((o) => {
        return o.split("_")[0];
      });

      chuan.forEach((o) => {
        let temp = this.bonusPermutation(max_prize_array, o);

        let temp_array = temp.map((o1) => {
          return o1.reduce((x, y) => new Decimal(x).mul(y), 1);
        });

        let t = new Decimal(
          temp_array.reduce((x, y) => new Decimal(x).add(y), 0)
        )
          .mul(2)
          .toFixed(2);

        let t1 = new Decimal(t).mul(this.tickets[i].bet_multi);
        this.max_prize = new Decimal(this.max_prize).add(t1);
      });

      // max_prize
      let max_prize_temp = this.max_prize;

      if (this.matches.length >= 6) {
        if (Number(max_prize_temp) > 1000000) {
          max_prize_temp = new Decimal(1000000).mul(this.project.bet_multi);
        }
      } else if (this.matches.length >= 4) {
        if (Number(max_prize_temp) > 500000) {
          max_prize_temp = new Decimal(500000).mul(this.project.bet_multi);
        }
      } else if (this.matches.length >= 2) {
        if (Number(max_prize_temp) > 200000) {
          max_prize_temp = new Decimal(200000).mul(this.project.bet_multi);
        }
      }

      this.max_prize = max_prize_temp;
    },
  },
};
</script>

<style lang="stylus" socped>
#detail-jclq{
   .info-sticky{
    background:#f0f0f0;
    padding-top: 10px;
    .info {
      padding: 10px;
      background: #ffffff;
      font-size: 14px;
      color: #888888;

      .bonus {
        color: #f00;
      }

      div {
        padding: 2px;
      }
    }
  }
  .order-list {
    .card {
      padding: 10px;
      background: #ffffff;
      margin-bottom: 10px;

      .top {
        font-size: 14px;
        text-align: center;
        margin-bottom: 10px;
      }

      .main {
        display: flex;
        flex-direction: row;

        .l {
          padding-right: 5px;
          font-size: 12px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          color: #888888;

          div {
            padding: 2px 0;
          }
        }

        .r {
          flex: 1;
        }
      }
    }

    .card.red{
      span{
        color:red!important;
      }
    }

    table {
      width: 100%;
      text-align: center;
      border-collapse: collapse;
      font-size: 12px;

      tr {
        height: 20px;
        border: 1px solid #ddd;
      }

      td {
        border: 1px solid #ddd;
        padding: 0 4px;
      }

      .red {
        color: red;
      }
    }
  }
  .child {
    .body {
      position: relative;
      background: #fff;
    }

    .child-content {
      position: relative;
      background: #fff;

      .child-tag {
        position: absolute;
        top: 10px;
        left: 0;
        right: 0;
        text-align: center;
        color: red;

        span {
          font-weight: bold;
          font-size: 20px;
        }
      }

        .child-type{
        padding: 45px 40px 0;
      }


      ul {
        background: #fff;
        padding: 0px 30px 0;

        li {
          margin-bottom: 0px !important;
          >div {
            font-size: 12px;
            display: flex;
            align-items: center;
            .order-week {
              width: 50px;
              margin-right: 10px;
              color: #888;
            }
            .order-result {
              flex: 1;
              span {
                &:last-child {
                  label {
                    &:last-child {
                      display: none;
                    }
                  }
                }
              }
            }
          }

        }
      }
    }

    .nav {
      display: flex;
      align-items: center;
      height: 50px;
      justify-content: space-evenly;
      background: #fff;
      .arrow-left {
        position: absolute;
        top: 50%;
        left: 10px;
        margin-top: -12px;
      }
      .arrow-right {
        position: absolute;
        top: 50%;
        right: 10px;
        margin-top: -12px;
      }
    }
  }
}
</style>
