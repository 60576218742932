<template>
  <div id="project-sports-detail">
    <c-nav-bar title="方案详情"></c-nav-bar>
    <!-- <d-recharge ref="recharge"></d-recharge> -->
    <div class="content">
      <div class="item">
        <van-tabs
          v-model="tabIndex"
          title-active-color="#38A6F3"
          color="#38A6F3"
        >
          <van-tab title="拆单详情" name="1">
            <div class="item-detail" v-if="project.project_id">
              <detail-jczq
                v-if="project.type === 'jczq'"
                :project="project"
                :options="options"
                :childOptions="childOptions"
                :tickets="tickets"
                :matches="matches"
                :rates="rates"
                :resultRates="resultRates"
                :bonus_sub_tax="bonus_sub_tax"
                @handlePrint="handlePrint"
                :type="1"
              >
              </detail-jczq>
              <detail-jclq
                v-if="project.type === 'jclq'"
                :project="project"
                :options="options"
                :childOptions="childOptions"
                :tickets="tickets"
                :matches="matches"
                :rates="rates"
                :resultRates="resultRates"
                :bonus_sub_tax="bonus_sub_tax"
                :type="1"
              ></detail-jclq>
            </div>
          </van-tab>
          <van-tab title="方案详情" name="2">
            <div class="item-detail" v-if="project.project_id">
              <detail-jczq
                @handleInit="handleInit"
                v-if="project.type === 'jczq'"
                :project="project"
                :options="options"
                :childOptions="childOptions"
                :tickets="tickets"
                :matches="matches"
                :rates="rates"
                :resultRates="resultRates"
                :bonus_sub_tax="bonus_sub_tax"
                @handlePrint="handlePrint"
                :type="2"
              >
              </detail-jczq>
              <detail-jclq
                @handleInit="handleInit"
                v-if="project.type === 'jclq'"
                :project="project"
                :options="options"
                :childOptions="childOptions"
                :tickets="tickets"
                :matches="matches"
                :rates="rates"
                :resultRates="resultRates"
                :bonus_sub_tax="bonus_sub_tax"
                :type="2"
              ></detail-jclq>
            </div>
          </van-tab>
          <van-tab title="优化详情" name="3" v-if="project.bet_items">
            <p class="tac red mt10 mb5">
              奖金优化后{{ betItems.length }}个子订单
            </p>
            <div class="pl10 pr10 pb20" style="background: #fff">
              <table class="mt10">
                <thead>
                  <tr>
                    <th></th>
                    <th>选单</th>
                    <th>注数</th>
                    <th>理论奖金</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(o, i) in betItems" :key="i">
                    <td>{{ o.chuan | formatProjectPassType }}</td>
                    <td class="tal">
                      <p v-for="(o1, i1) in o.match" :key="i1">
                        {{ o1.week | formatWeekday }} {{ o1.number }} (<span
                          v-if="o1.type === 'f_rqspf_f'"
                        >
                          <span :class="o1.let > 0 ? 'green' : 'red'">{{
                            o1.let > 0 ? "+" + o1.let : o1.let
                          }}</span>
                          {{ o1.code | formatSportsRqspf }}</span
                        >
                        <span v-if="o1.type === 'f_zjq_f'">{{
                          o1.code | formatSportsZjq
                        }}</span>
                        <span v-if="o1.type === 'f_bf_f'">{{
                          o1.code | formatSportsBf
                        }}</span>
                        <span v-if="o1.type === 'f_bqspf_f'">{{
                          o1.code | formatSportsBqc
                        }}</span>
                        <span v-if="o1.type === 'f_spf_f'">{{
                          o1.code | formatSportsSpf
                        }}</span
                        >)
                      </p>
                    </td>
                    <td>{{ o.bet }}注</td>
                    <td>{{ o.rate_mul }}元</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </van-tab>
          <van-tab title="实票照片" name="4">
            <div v-if="project.ticket_img_urls">
              <div
                v-if="
                  project.ticket_img_urls.length > 0 && againUpload === false
                "
              >
                <van-swipe class="ticket-image" :autoplay="3000">
                  <van-swipe-item
                    class="tac"
                    v-for="(o, i) in project.ticket_img_urls"
                    :key="i"
                  >
                    <img style="max-height: 60vh; max-width: 100%" :src="o" />
                  </van-swipe-item>
                </van-swipe>
                <van-button
                  class="detail-upload-btn"
                  size="small"
                  type="info"
                  block
                  @click="againUpload = true"
                  >重新上传</van-button
                >
              </div>
              <div
                v-if="project.ticket_img_urls.length === 0 || againUpload"
                class="detail-upload mt10"
              >
                <!-- <div class="header tal mb10">
                  <van-uploader
                    :after-read="handleUploadImage"
                    v-model="ticketsImage"
                    :max-count="4"
                  >
                  </van-uploader>
                </div> -->

                <!-- <van-button
                  class="detail-upload-btn"
                  size="small"
                  type="info"
                  block
                  @click="handlUpload()"
                  >上传</van-button
                > -->
              </div>
            </div>
          </van-tab>
        </van-tabs>
      </div>
    </div>
    <!-- <p-tabbar
      ref="tabbar"
      :type="type"
      @handleAllPrint="handleAllPrint"
      @handleInit="handleInit"
      :state="project.state"
      :ticketImage="project.ticket_img_urls"
    ></p-tabbar> -->
  </div>
</template>

<script>
import CNavBar from "@/components/CNavBar";
// import PTabbar from "@/components/PTabbar";

import DetailJczq from "@/views/project/detail/Jczq";
import DetailJclq from "@/views/project/detail/Jclq";
// import DRecharge from "@/components/DRecharge";

// import common from "@/api/common";
import project from "@/api/project";

import { Decimal } from "decimal.js";

export default {
  name: "project-sports-detail",
  data() {
    return {
      tabIndex: 1,
      project: {},
      matches: [],
      options: {},
      rates: {},
      resultRates: {},
      tickets: [],
      childOptions: [],
      type: "jczq",
      pk: null,
      ticketsImage: [],
      bonus_sub_tax: 0,
      againUpload: false,
      formatMatch: {},
      betItems: [],
    };
  },
  components: {
    CNavBar,
    // PTabbar,
    DetailJczq,
    DetailJclq,
    // DRecharge,
  },
  created() {
    this.type = this.$route.query.type;
    this.pk = this.$route.query.pk;
    this.handleInit();
    this.tabIndex = this.$route.query.from === "ticket" ? 2 : 0;
  },
  methods: {
    // handleTabChange(val) {
    //   location.hash = "#" + val;
    // },
    handleCalBetItems(val) {
      this.betItems = val.map((o) => {
        let arrBetMatch = o.bet_match.split(",");
        let arrBetCodes = o.bet_codes.split(",");
        let arrBetRates = o.bet_rates.split(",");

        let rate_mul = 1;

        let match = arrBetMatch.map((o1, i1) => {
          let t = arrBetCodes[i1].split("|");
          let r = arrBetRates[i1].split("|");
          let type = "";
          let rate = "";
          let code = "";
          if (t[0]) {
            type = "f_rqspf_f";
            rate = r[0];
            code = t[0];
          } else if (t[1]) {
            type = "f_zjq_f";
            rate = r[1];
            code = t[1];
          } else if (t[2]) {
            type = "f_bf_f";
            rate = r[2];
            code = t[2];
          } else if (t[3]) {
            type = "f_bqspf_f";
            rate = r[3];
            code = t[3];
          } else if (t[4]) {
            type = "f_spf_f";
            rate = r[4];
            code = t[4];
          }
          rate_mul = new Decimal(rate_mul).mul(rate);
          return {
            home: this.formatMatch[o1].home_team,
            week: this.formatMatch[o1].number.substr(8, 1),
            number: this.formatMatch[o1].number.substr(9, 3),
            let: this.formatMatch[o1].ft_let_point_multi,
            type: type,
            rate: rate,
            code: code,
          };
        });

        return {
          chuan: o.pass_type,
          bet: o.bet_multi,
          match: match,
          rate_mul: (rate_mul * 2 * o.bet_multi).toFixed(2),
        };
      });
    },
    // handleIsLast(obj) {
    //   this.$refs.tabbar.isLast = obj;
    // },
    handleInit() {
      let pk = this.$route.query.pk;
      project.getSports(pk).then((res) => {
        this.project = res.results.project;
        this.matches = res.results.matches.sort((a, b) => {
          return Number(a.number.substr(8)) - Number(b.number.substr(8));
        });
        this.formatMatch = {};
        this.matches.forEach((o) => {
          this.formatMatch[o.number] = o;
        });
        this.tickets = res.results.tickets;
        this.handlePackOptionData(this.project);
        res.results.tickets.forEach((o) => {
          let y = this.handlePackChildOptionData(o);
          this.childOptions.push(y);
        });
        this.bonus_sub_tax = new Decimal(this.project.bonus_money).sub(
          new Decimal(this.project.tax)
        );

        this.ticketsImage = res.results.project.ticket_img_urls.map((o) => {
          return {
            url: o,
            status: "success",
          };
        });

        if (this.project && this.project.bet_items) {
          this.handleCalBetItems(this.project.bet_items);
        }
      });
      // .catch((res) => {
      //   this.$refs.recharge.handleShow(res.code, res.message);
      // });
    },
    handlePackOptionData(data) {
      let arrBetMatch = data.bet_match.split(",");
      let arrBetCodes = data.bet_codes.split(",");
      let arrBetRates = data.bet_rates.split(",");
      let objOptions = {};
      let objRates = {};
      arrBetMatch.forEach((item, i) => {
        let strBetCodes = arrBetCodes[i];
        let strBetRates = arrBetRates[i];
        let arrBetItems = strBetCodes.split("|");
        let arrBetRateItems = strBetRates.split("|");

        if (this.$route.query.type === "jczq") {
          console.log("arrBetItems", arrBetItems);
          console.log("aa", arrBetItems[4].split("/"));
          objOptions[item] = {
            f_rqspf_f:
              arrBetItems[0] !== ""
                ? this.reverseArray(arrBetItems[0].split("/"))
                : [],
            f_zjq_f: arrBetItems[1] !== "" ? arrBetItems[1].split("/") : [],
            f_bf_f: arrBetItems[2] !== "" ? arrBetItems[2].split("/") : [],
            f_bqspf_f: arrBetItems[3] !== "" ? arrBetItems[3].split("/") : [],
            f_spf_f:
              arrBetItems[4] !== ""
                ? this.reverseArray(arrBetItems[4].split("/"))
                : [],
          };
          objRates[item] = {
            f_rqspf_f:
              arrBetRateItems[0] !== ""
                ? this.reverseArray(arrBetRateItems[0].split("/"))
                : [],
            f_zjq_f:
              arrBetRateItems[1] !== "" ? arrBetRateItems[1].split("/") : [],
            f_bf_f:
              arrBetRateItems[2] !== "" ? arrBetRateItems[2].split("/") : [],
            f_bqspf_f:
              arrBetRateItems[3] !== "" ? arrBetRateItems[3].split("/") : [],
            f_spf_f:
              arrBetRateItems[4] !== ""
                ? this.reverseArray(arrBetRateItems[4].split("/"))
                : [],
          };
        } else {
          objOptions[item] = {
            b_rfsf_f:
              arrBetItems[0] !== ""
                ? this.reverseArray(arrBetItems[0].split("/"))
                : [],
            b_sf_f:
              arrBetItems[1] !== ""
                ? this.reverseArray(arrBetItems[1].split("/"))
                : [],
            b_dxf_f: arrBetItems[2] !== "" ? arrBetItems[2].split("/") : [],
            b_sfc_f: arrBetItems[3] !== "" ? arrBetItems[3].split("/") : [],
          };
          objRates[item] = {
            b_rfsf_f:
              arrBetRateItems[0] !== ""
                ? this.reverseArray(arrBetRateItems[0].split("/"))
                : [],
            b_sf_f:
              arrBetRateItems[1] !== ""
                ? this.reverseArray(arrBetRateItems[1].split("/"))
                : [],
            b_dxf_f:
              arrBetRateItems[2] !== "" ? arrBetRateItems[2].split("/") : [],
            b_sfc_f:
              arrBetRateItems[3] !== "" ? arrBetRateItems[3].split("/") : [],
          };
        }
      });
      this.options = objOptions;

      this.rates = objRates;

      let i3 = {};
      Object.keys(objRates).forEach((o) => {
        this.$set(i3, o, {});

        Object.keys(objRates[o]).forEach((o1) => {
          this.$set(i3[o], o1, {});

          objRates[o][o1].forEach((o2, i2) => {
            this.$set(i3[o][o1], objOptions[o][o1][i2], o2);
          });
        });
      });

      this.$set(this, "resultRates", JSON.parse(JSON.stringify(i3)));
    },
    handlePackChildOptionData(data) {
      let arrBetMatch = data.bet_match.split(",");
      let arrBetCodes = data.bet_codes.split(",");
      let objOptions = {};
      arrBetMatch.forEach((item, i) => {
        let strBetCodes = arrBetCodes[i];
        let arrBetItems = strBetCodes.split("|");

        if (this.$route.query.type === "jczq") {
          if (data.play_type === "f_hhgg_f") {
            objOptions[item] = {
              f_rqspf_f:
                arrBetItems[0] !== ""
                  ? this.reverseArray(arrBetItems[0].split("/"))
                  : [],
              f_zjq_f: arrBetItems[1] !== "" ? arrBetItems[1].split("/") : [],
              f_bf_f: arrBetItems[2] !== "" ? arrBetItems[2].split("/") : [],
              f_bqspf_f: arrBetItems[3] !== "" ? arrBetItems[3].split("/") : [],
              f_spf_f:
                arrBetItems[4] !== ""
                  ? this.reverseArray(arrBetItems[4].split("/"))
                  : [],
            };
          } else {
            let temp = arrBetItems[0] !== "" ? arrBetItems[0].split("/") : [];
            // if (
            //   data.play_type === "f_spf_f" ||
            //   data.play_type === "f_rqspf_f"
            // ) {
            //   temp = temp.reverse();
            // }
            objOptions[item] = {};
            objOptions[item][data.play_type] = temp;
          }
        } else {
          if (data.play_type === "b_hhgg_f") {
            objOptions[item] = {
              b_rfsf_f:
                arrBetItems[0] !== ""
                  ? this.reverseArray(arrBetItems[0].split("/"))
                  : [],
              b_sf_f:
                arrBetItems[1] !== ""
                  ? this.reverseArray(arrBetItems[1].split("/"))
                  : [],
              b_dxf_f: arrBetItems[2] !== "" ? arrBetItems[2].split("/") : [],
              b_sfc_f: arrBetItems[3] !== "" ? arrBetItems[3].split("/") : [],
            };
          } else {
            let temp = arrBetItems[0] !== "" ? arrBetItems[0].split("/") : [];
            // if (data.play_type === "b_rfsf_f" || data.play_type === "b_sf_f") {
            //   temp = temp.reverse();
            // }
            objOptions[item] = {};
            objOptions[item][data.play_type] = temp;
          }
        }
      });
      return objOptions;
    },
    handleAllPrint() {
      this.tickets.forEach((o) => {
        this.handlePrint(o);
      });
    },
    handlePrint(obj) {
      if (obj.type === "jclq") {
        this.$toast("暂不支持");
        return;
      }
      let matchList = obj.bet_match.split(",");
      let bet_match = [];
      matchList.forEach((res) => {
        if (res.toString().slice(res.length - 4, res.length - 3) === "0") {
          bet_match.push(
            "7" + res.toString().slice(res.length - 3, res.length)
          );
        } else {
          bet_match.push(res.toString().slice(res.length - 4, res.length));
        }
      });
      let arr_bet_codes = [];
      let temp = -1;
      switch (obj.play_type) {
        case "f_hhgg_f":
          temp = -1;
          break;
        case "f_spf_f":
          temp = 4;
          break;
        case "f_bf_f":
          temp = 2;
          break;
        case "f_zjq_f":
          temp = 1;
          break;
        case "f_bqspf_f":
          temp = 3;
          break;
        case "f_rqspf_f":
          temp = 0; // to do
          break;
      }

      obj.bet_codes.split(",").forEach((item) => {
        let posite = ["", "", "", "", ""];
        if (temp === -1) {
          arr_bet_codes.push(item);
        } else {
          posite[temp] = item;
          arr_bet_codes.push(posite.join("|"));
        }
      });
      let bet_codes = arr_bet_codes.join(",");
      let assembleData = {
        projectID: obj.project_id,
        count: obj.sum,
        strBetMoney: parseInt(obj.bet_money),
        index: obj.childIndex + 1,
        strBetMulti: obj.bet_multi,
        strBetMatch: bet_match.join(","),
        strBetCodes: bet_codes,
        strPassType: obj.pass_type,
        strType: obj.type,
        strPlayType: obj.play_type,
      };
      try {
        // eslint-disable-next-line
        console.log(assembleData)
        // android.ticketOut(assembleData);
        // android.ticketOutNum(JSON.stringify(assembleData));
        // android.ticketOutFoot(JSON.stringify(assembleData));
      } catch {
        this.$toast("此次弹框说明，该设备是非安卓平台");
      }
    },

    handlePrintDetail(m, b, p) {
      // 投注顺序为 让球胜平负 总进球 比分 半全场 胜平负
      // 打印机顺序 胜平负 a , 让球胜平负 b, 比分 c, 总进球 d, 半全场 e
      let temp = b.map((o) => {
        let arr = [];
        let f = {
          f_rqspf_f: 0,
          f_zjq_f: 1,
          f_bf_f: 2,
          f_bqspf_f: 3,
          f_spf_f: 4,
        };

        if (p === "f_hhgg_f") {
          arr = o.trim().split("|");
        } else {
          arr[f[p]] = o.trim();
        }

        let o2 = arr.map((o1, i1) => {
          let g = [];
          let l = ["b", "d", "c", "e", "a"];
          if (o1) {
            g = o1.split("/").map((o3) => {
              return l[i1] + o3;
            });
          }
          return g.join("/");
        });

        // 顺序调整
        let result = [o2[4], o2[0], o2[2], o2[1], o2[3]].filter(function (s) {
          return s && s.trim();
        });

        return result.join("/");
      });

      return temp
        .map((o, i) => {
          return m[i].substr(8, 1) + "|" + m[i].substr(9, 3) + "|" + o;
        })
        .join(",");
    },
    handlePrintBetType(c, b) {
      let bet = "";
      let type = b;
      switch (c) {
        case 3:
          if (type === "2_1") {
            bet = "3_3";
          } else if (type === "2_1,3_1") {
            bet = "3_4";
          } else if (type === "3_1") {
            bet = "3_1";
          }
          break;
        case 4:
          if (type === "2_1") {
            bet = "4_6";
          } else if (type === "3_1") {
            bet = "4_4";
          } else if (type === "3_1,4_1") {
            bet = "4_5";
          } else if (type === "4_1") {
            bet = "4_1";
          }
          break;
        case 5:
          if (type === "4_1") {
            bet = "5_5";
          } else if (type === "4_1,5_1") {
            bet = "5_6";
          } else if (type === "2_1") {
            bet = "5_10";
          } else if (type === "3_1,4_1,5_1") {
            bet = "5_16";
          } else if (type === "2_1,3_1") {
            bet = "5_20";
          } else if (type === "2_1,3_1,4_1,5_1") {
            bet = "5_26";
          } else if (type === "5_1") {
            bet = "5_1";
          }
          break;
        case 6:
          if (type === "5_1") {
            bet = "6_6";
          } else if (type === "5_1,6_1") {
            bet = "6_7";
          } else if (type === "2_1") {
            bet = "6_15";
          } else if (type === "3_1") {
            bet = "6_20";
          } else if (type === "4_1,5_1,6_1") {
            bet = "6_22";
          } else if (type === "2_1,3_1") {
            bet = "6_35";
          } else if (type === "3_1,4_1,5_1,6_1") {
            bet = "6_42";
          } else if (type === "2_1,3_1,4_1") {
            bet = "6_50";
          } else if (type === "2_1,3_1,4_1,5_1,6_1") {
            bet = "6_57";
          } else if (type === "6_1") {
            bet = "6_1";
          }
          break;
        case 7:
          if (type === "6_1") {
            bet = "7_7";
          } else if (type === "6_1,7_1") {
            bet = "7_8";
          } else if (type === "5_1") {
            bet = "7_21";
          } else if (type === "4_1") {
            bet = "7_35";
          } else if (type === "2_1,3_1,4_1,5_1,6_1,7_1") {
            bet = "7_120";
          } else if (type === "7_1") {
            bet = "7_1";
          }
          break;
        case 8:
          if (type === "7_1") {
            bet = "8_8";
          } else if (type === "7_1,8_1") {
            bet = "8_9";
          } else if (type === "6_1") {
            bet = "8_28";
          } else if (type === "5_1") {
            bet = "8_56";
          } else if (type === "4_1") {
            bet = "8_70";
          } else if (type === "2_1,3_1,4_1,5_1,6_1,7_1,8_1") {
            bet = "8_247";
          } else if (type === "8_1") {
            bet = "8_1";
          }
          break;
      }

      return bet;
    },
    handlePrintCode(p) {
      let temp = "";
      switch (p) {
        case "f_hhgg_f":
          temp = 56;
          break;
        case "f_spf_f":
          temp = 51;
          break;
        case "f_bf_f":
          temp = 52;
          break;
        case "f_zjq_f":
          temp = 53;
          break;
        case "f_bqspf_f":
          temp = 54;
          break;
        case "f_rqspf_f":
          temp = 55; // to do
          break;
      }
      return temp;
    },

    // handleUploadImage(file) {
    //   file.status = "uploading";
    //   file.message = "上传中...";
    //   let data = new FormData();
    //   data.append("file", file.file);
    //   data.temp = "ticket";
    //   common
    //     .upload(data)
    //     .then((res) => {
    //       if (!res.results.url) {
    //         this.$toast("图片上传失败，请重新上传");
    //         file.status = "failed";
    //         file.message = "上传失败";
    //         return false;
    //       }
    //       file.status = "success";
    //       file.url = res.results.url;
    //     })
    //     .catch((err) => {
    //       this.$toast({
    //         message: err.message,
    //       });
    //       file.status = "failed";
    //       file.message = "上传失败";
    //     });
    // },
    handlUpload() {
      let ticket_img_urls = this.ticketsImage.map((o) => {
        return o.url;
      });
      if (ticket_img_urls.length < 1) {
        this.$toast("还未上传票照");
        return;
      }
      let t = ticket_img_urls.filter((o) => {
        return o;
      });
      if (t.length === 0) {
        this.$toast("票照不能为空，请重新上传");
        return false;
      }
      this.$dialog
        .confirm({
          message: "是否确认上传？",
        })
        .then(() => {
          this.handleSucceed().then(() => {
            project
              .upload(this.pk, {
                ticket_img_urls: ticket_img_urls,
              })
              .then(() => {
                this.handleInit();
                this.$toast("票照上传成功");
                this.$router.push({ name: "project-list", hash: "#0" });
              })
              .catch((err) => {
                this.$toast({
                  message: err.message,
                });
              });
          });
        });
    },
    handleSucceed() {
      return new Promise((resolve) => {
        project
          .printSportsSucceed(this.pk)
          .then(() => {
            resolve();
          })
          .catch((err) => {
            this.$toast({
              message: err.message,
            });
          });
      });
    },
  },
};
</script>

<style lang="stylus" scoped>
#project-sports-detail {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .content {
    height: clac(100% - 46px);
    flex: 1;
    position: absolute;
    left: 0;
    right: 0;
    top: 46px;
    bottom: 44px;

    .info {
      padding: 10px;
      background: #ffffff;
      font-size: 14px;
      color: #888888;

      .bonus {
        color: #f00;
      }

      div {
        padding: 2px;
      }
    }

    .item-detail {
      overflow: auto;
    }
  }

  .ticket-image {
    width: 90%;
    margin: 20px auto;
  }

  .gray {
    color: #666;
    padding: 0 5px;
  }

  .detail-upload {
    background #fff
    padding: 20px;
    margin-bottom: 10px;
  }
  .detail-upload-btn {
    width: 172px;
    margin: 0 auto;
  }
}
table {
    width: 100%;
    text-align: center;
    border-collapse: collapse;
    font-size: 12px;
    thead{
      height 30px
      td{
        color #72748A

      }
    }

    tr {
      height: 20px;
      border: 1px solid #F0F1F5;
    }

    td {
      border: 1px solid #F0F1F5;
      padding: 4px 6px;
      .td_div{
        display inline-block
        width 33%
        .text1{
          color #575969
          font-size 15px
          // display block
        }
        .text2{
          color #72748A
          font-size 13px
          // display block
        }
      }
    }

    .red {
      span{
        color: red!important;
      }
    }
  }
</style>
