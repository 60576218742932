<template>
  <div id="detail-sfc">
    <van-sticky :offset-top="90">
      <div class="info-sticky">
        <div class="info">
          <van-row type="flex" justify="space-between">
            <van-col
              style="color: rgb(46, 91, 253)"
              @click="handleToUSerDetail(project.user_id)"
              >用户昵称：{{ project.user.nickname
              }}<span v-if="project.user.remark"
                >（{{ project.user.remark }}）</span
              ></van-col
            >
            <van-col></van-col>
          </van-row>
          <van-row type="flex" justify="space-between">
            <van-col>{{ project.project_id }}</van-col>
            <van-col>{{ project.state | formatProjectState }}</van-col>
          </van-row>
          <van-row type="flex" justify="space-between">
            <van-col>
              <div>
                {{ project.type | formatProjectType }} 第{{
                  project.bet_match
                }}期
              </div>
            </van-col>
            <van-col>
              <div>{{ project.bet_multi }}倍 ¥{{ project.bet_money }}元</div>
            </van-col>
            <van-col v-if="project.state >= 3">
              <div class="bonus">中奖：¥{{ project.bonus_money }}元</div>
            </van-col>
          </van-row>
          <van-row type="flex" justify="space-between">
            <van-col>
              下单时间：{{ project.created_at | formatDateTime }}
            </van-col>
            <van-col>
              <span v-if="project.state >= 0">出票时间：</span>
              <span v-else>撤单时间：</span>
              {{ project.handled_at | formatDateTime }}
            </van-col>
          </van-row>
          <van-row v-if="project.state >= 3 && bonus_sub_tax > 0">
            <div class="bonus">
              奖金：¥{{ project.bonus_money }}元 - ¥{{ project.tax }}元(税) =
              ¥{{ bonus_sub_tax }}元
            </div>
          </van-row>
        </div>
      </div>
    </van-sticky>

    <ul class="order-list mt10 pb50" v-if="type === 2">
      <li
        class="card"
        v-for="(item, key) in matches"
        v-show="options[key][0] !== '_'"
        :key="key"
      >
        <div class="top">
          {{ item.index >= 10 ? item.index : "0" + item.index }}
          <span>{{ item.home_team }}</span>
          <span class="ml10 mr10">vs</span>
          <span>{{ item.away_team }}</span>
        </div>
        <div class="main">
          <div class="l">
            <div>
              {{ item.league }}
            </div>
            <div>
              {{ item.start_time | formatDate }}
            </div>
          </div>
          <div class="r">
            <table>
              <tr>
                <td>选项</td>
                <td>赛果</td>
              </tr>
              <tr>
                <td class="wid-80">
                  <span
                    :class="prizeCodes[key] === val ? 'red' : ''"
                    v-for="(val, k) in options[key]"
                    :key="k"
                  >
                    {{ val | formatSportsSpf }}
                  </span>
                </td>
                <td class="wid-20">
                  {{ prizeCodes[key] | formatSportsSpf }}
                </td>
              </tr>
            </table>
          </div>
        </div>
      </li>
    </ul>
    <div class="child" v-if="type === 1">
      <p class="red tac" style="background: #fff">
        总方案金额：{{ project.bet_money }}元
      </p>
      <div class="title">
        <van-row
          type="flex"
          style="height: 44px; background: #fff"
          justify="space-between"
          align="center"
        >
          <van-col>
            <span class="ml16"
              >实票(共{{ actualTickets.length }}页{{
                handleCalcCount()
              }}张)</span
            >
          </van-col>
          <van-col span="12" class="tar">
            <span class="mr5"
              >订单尾号：{{ project.project_id.slice(-6) }}
            </span>
          </van-col>
        </van-row>
      </div>

      <div class="body mt10 pb50">
        <van-swipe
          ref="swipe"
          @change="handleChange"
          :touchable="false"
          :show-indicators="false"
        >
          <van-swipe-item v-for="(item, key) in actualTickets" :key="key">
            <div class="child-content">
              <ul class="order-list" v-if="actualTickets.length > 0">
                <li v-for="(item1, key1) in item" :key="key1">
                  <div class="child-tag">
                    注意：该投注单需要扫描
                    <span> {{ actualTicketsDetail[key].count }} </span>次<span>
                      {{ actualTicketsDetail[key].bet_multi }} </span
                    >倍
                  </div>
                  <div class="order-list-head" v-show="key1 === 0">
                    <span v-for="(o, i) in indexArray" :key="i">
                      {{ o }}
                    </span>
                  </div>
                  <div class="order-list-body">
                    <div v-for="(o, i) in indexArray" :key="i">
                      <span
                        v-show="
                          handleResultShow(item1.type, item1.value[i], '3')
                        "
                      >
                        {{ item1.value[i].includes("3") ? "3" : "*" }}
                      </span>
                      <span
                        v-show="
                          handleResultShow(item1.type, item1.value[i], '1')
                        "
                      >
                        {{ item1.value[i].includes("1") ? "1" : "*" }}
                      </span>
                      <span
                        v-show="
                          handleResultShow(item1.type, item1.value[i], '0')
                        "
                      >
                        {{ item1.value[i].includes("0") ? "0" : "*" }}
                      </span>
                      <!-- 兼容102 -->
                      <span
                        v-show="
                          item1.type === '102' && item1.value[i][0] === '_'
                        "
                      >
                        *
                      </span>
                    </div>
                  </div>
                  <div class="tac mt10">
                    金额：{{
                      handleGetM(
                        actualTicketsDetail[key].bet_money,
                        item.length
                      )
                    }}元
                  </div>
                </li>
              </ul>
            </div>
          </van-swipe-item>
        </van-swipe>

        <div class="nav">
          <van-icon
            class="arrow-left"
            v-show="actualTickets.length > 1"
            name="arrow-left"
            @click="handleLast"
            style="font-weight: bold"
            color="#1989fa"
            size="34"
          />
          <!-- <van-button type="primary" size="small" @click="handlePrint"
            >第{{ childIndex + 1 }}页 点击打印<span
              v-show="actualTickets.length > 1"
              >{{ actualTicketsDetail[childIndex].count }}张票</span
            >
          </van-button> -->
          <van-icon
            class="arrow-right"
            v-show="actualTickets.length > 1"
            name="arrow"
            @click="handleNext"
            style="font-weight: bold"
            color="#1989fa"
            size="34"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Decimal } from "decimal.js";
import issue from "@/api/issue";
export default {
  name: "detail-sfc",
  props: ["project", "matches", "tickets", "type", "bonus_sub_tax"],
  data() {
    return {
      match: {},
      options: [],
      prizeCodes: [],
      childIndex: 0,
      childOptions: {},
      actualTickets: [],
      actualTicketsDetail: [],
      indexArray: [
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
        "13",
        "14",
      ],
    };
  },
  created() {
    this.matches = this.matches.sort((a, b) => {
      return a["index"] - b["index"];
    });

    this.handlePackOptionData(this.project);
    this.handlePackChildOptionData(this.tickets);
    this.handleActualTickets(this.tickets);
    this.handleJudgeLast();
  },
  methods: {
    handleToUSerDetail(val) {
      this.$router.push({
        name: "account-book-customer-detail",
        query: {
          user_id: val,
        },
      });
    },
    handleGetM(a, b) {
      return new Decimal(a).div(b);
    },
    handleCalcCount() {
      return this.actualTicketsDetail
        .map((o) => {
          return o.count;
        })
        .reduce((x, y) => x + y, 0);
    },
    handleResultShow(t, o, v) {
      if (t === "101" || t === "102") {
        if (!o.includes(v)) {
          return false;
        }
      }
      return true;
    },
    handleActualTickets(data) {
      let actualTickets = [];
      this.actualTickets = [];
      this.actualTicketsDetail = [];
      data = data.sort((a, b) => {
        return a.index - b.index;
      });
      let type = this.project.type;
      if (type === "sfc14") {
        let t = data.map((o) => {
          let temp = o.bet_codes.split(",").map((o1) => {
            return o1.split("|");
          });
          return [
            {
              type: o.play_type,
              value: temp,
              bet_money: o.bet_money,
            },
          ];
        });
        actualTickets = t;
      } else if (type === "sfc9") {
        let ds = [];
        let fs = [];
        let ds_split = [];
        data.forEach((o) => {
          let temp = o.bet_codes.split(",").map((o1) => {
            return o1.split("|");
          });
          if (o.play_type === "102") {
            ds.push({
              type: o.play_type,
              value: temp,
              bet_money: o.bet_money,
            });
          } else if (o.play_type === "104") {
            fs.push([
              {
                type: o.play_type,
                value: temp,
                bet_money: o.bet_money,
              },
            ]);
          }
        });

        ds.forEach((o, i) => {
          if (i % 5 === 0) {
            ds_split.push(ds.slice(i, i + 5));
          }
        });
        actualTickets = ds_split.concat(fs);
      }

      let over50Count = Math.ceil(this.project.bet_multi / 50);

      actualTickets.forEach((o) => {
        let t = this.project.bet_multi % 50;
        let b = o
          .map((o1) => {
            return Number(o1.bet_money);
          })
          .reduce((x, y) => x + y, 0);
        if (over50Count > 1) {
          var i = 0;
          while (i < 2) {
            if (i === 0) {
              this.actualTicketsDetail.push({
                count: parseInt(over50Count) - 1,
                bet_multi: 50,
                bet_money: b * 50,
              });
            } else {
              this.actualTicketsDetail.push({
                count: 1,
                bet_multi: t,
                bet_money: b * t,
              });
            }
            this.actualTickets.push(o);
            i++;
          }
        } else {
          this.actualTickets.push(o);
          this.actualTicketsDetail.push({
            count: 1,
            bet_multi: t,
            bet_money: b * t,
          });
        }
      });
    },
    handleChange(val) {
      this.childIndex = val;
      this.handleJudgeLast();
      this.handleScrollTo();
    },
    handleJudgeLast() {
      if (this.childIndex + 1 === this.actualTickets.length) {
        this.$emit("handleIsLast", true);
      } else {
        this.$emit("handleIsLast", false);
      }
    },
    handleScrollTo() {
      let order_list_height =
        document.getElementsByClassName("order-list")[0].clientHeight + 10;
      document.getElementsByClassName("item-detail")[0].scrollTop =
        order_list_height;
    },
    handleLast() {
      this.$refs.swipe.prev();
    },
    handleNext() {
      this.$refs.swipe.next();
    },
    handlePrint() {
      this.$dialog
        .alert({
          title: "温馨提示",
          message: "当前奖期为" + this.project.bet_match + "，是否继续打印？",
          showCancelButton: true,
        })
        .then(() => {
          let obj = this.tickets[this.childIndex];
          obj.bet_multi = this.project.bet_multi;
          obj.sum = this.tickets.length;
          obj.childIndex = this.childIndex;
          this.$emit("handlePrint", obj);
        })
        .catch(() => {});
    },
    handlePackOptionData(data) {
      let arrBetCodes = [
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
      ];
      data.bet_items.forEach((o) => {
        o.bet_codes.split(",").forEach((o1, i1) => {
          if (arrBetCodes[i1].length === 0) {
            arrBetCodes[i1].push(o1);
          } else if (!arrBetCodes[i1].includes(o1) && o1 !== "_") {
            if (arrBetCodes[i1][0] === "_") {
              arrBetCodes[i1][0] = o1;
            } else {
              arrBetCodes[i1].push(o1);
            }
          }
        });
      });

      this.options = arrBetCodes.map((o) => {
        return o.join("").split("|").reverse();
      });
      issue
        .get({ type: data.bet_items[0].type, number: data.bet_items[0].number })
        .then((res) => {
          this.prizeCodes = res.results.prize_code.split(",");
          if (this.prizeCodes.length !== 14) {
            this.prizeCodes = [];
          }
        })
        .catch(() => {});
    },
    handlePackChildOptionData(data) {
      data = data.sort((a, b) => {
        return a.index - b.index;
      });
      data.forEach((o, i) => {
        let arrBetCodes = o.bet_codes.split(",");
        let temp = arrBetCodes.map((o) => {
          return o !== "_" ? o.split("|").reverse() : [];
        });
        this.childOptions[i] = temp;
      });
    },
  },
};
</script>

<style lang="stylus" scoped>
#detail-sfc {
  .info-sticky{
    background:#f0f0f0;
    padding-top: 10px;
    .info {
      padding: 10px;
      background: #ffffff;
      font-size: 14px;
      color: #888888;

      .bonus {
        color: #f00;
      }

      div {
        padding: 2px;
      }
    }
  }

  .order-list {
    .card {
      padding: 10px;
      background: #ffffff;
      margin-bottom: 10px;
      border-radius: 5px;

      .top {
        font-size: 14px;
        text-align: center;
        margin-bottom: 10px;
      }

      .main {
        display: flex;
        flex-direction: row;

        .l {
          padding-right: 5px;
          font-size: 12px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          color: #888888;

          div {
            padding: 2px 0;
          }
        }

        .r {
          flex: 1;
        }
      }
    }

    table {
      width: 100%;
      text-align: center;
      border-collapse: collapse;
      font-size: 12px;

      tr {
        height: 20px;
        border: 1px solid #ddd;
      }

      td {
        border: 1px solid #ddd;
        padding: 0 4px;
      }
    }
  }

  .child {
    .body {
      position: relative;
      background: #fff;
    }
    .child-content {
      position: relative;

      .child-tag {
        position: absolute;
        top: 10px;
        left: 0;
        right: 0;
        text-align: center;
        color: red;

        span {
          font-weight: bold;
          font-size: 20px;
        }
      }

      ul {
        background: #fff;
        font-size: 14px;
        padding: 42px 35px 0;

        li {
          margin-bottom: 0px !important;
          .order-list-head {
            display flex
            span {
              flex 1
              color #333
              text-align center
              font-size 16px
            }
          }
          .order-list-body {
            display flex
            justify-content center
            >div {
              flex 1
              span {
                color #888
                text-align center
                display block
              }
            }

          }
        }
      }
    }

    .nav {
      display: flex;
      align-items: center;
      height: 50px;
      justify-content: space-evenly;
      background: #fff;
      .arrow-left {
        position: absolute;
        top: 50%;
        left: 10px;
        margin-top: -12px;
      }
      .arrow-right {
        position: absolute;
        top: 50%;
        right: 10px;
        margin-top: -12px;
      }
    }
  }
}
</style>
