import api from "@/api";

const issue = {
  get(params) {
    return api.get(`/issue/number`, {
      params: params,
    });
  },
};

export default issue;
