<template>
  <div id="detail-jczq">
    <van-sticky v-if="type === 2" :offset-top="90">
      <div class="info-sticky">
        <div class="info">
          <van-row type="flex" justify="space-between">
            <van-col
              style="color: rgb(46, 91, 253)"
              @click="handleToUSerDetail(project.user_id)"
              >用户昵称：{{ project.user.nickname
              }}<span v-if="project.user.remark"
                >（{{ project.user.remark }}）</span
              ></van-col
            >
            <van-col></van-col>
          </van-row>
          <van-row type="flex" justify="space-between">
            <van-col>{{ project.project_id }}</van-col>
            <van-col>{{ project.state | formatProjectState }}</van-col>
          </van-row>
          <van-row type="flex" justify="space-between">
            <van-col>
              <div>
                {{ project.type | formatProjectType }}
                <span v-for="(o, i) in project.pass_type.split(',')" :key="i">
                  {{ o | formatProjectPassType }}
                </span>
              </div>
            </van-col>
            <van-col>
              <div>{{ project.bet_multi }}倍 ¥{{ project.bet_money }}元</div>
            </van-col>
            <van-col v-if="project.state >= 3">
              <div class="bonus">中奖：¥{{ project.bonus_money }}元</div>
            </van-col>
          </van-row>
          <van-row type="flex" justify="space-between">
            <van-col>
              下单时间：{{ project.created_at | formatDateTime }}
            </van-col>
            <van-col>
              <span v-if="project.state >= 0">出票时间：</span>
              <span v-else>撤单时间：</span>
              {{ project.handled_at | formatDateTime }}
            </van-col>
          </van-row>
          <van-row v-if="project.state >= 3 && bonus_sub_tax > 0">
            <div class="bonus">
              奖金：¥{{ project.bonus_money }}元 - ¥{{ project.tax }}元(税)
              <span v-if="project.follow_type === 1"
                >- ¥{{ handleCommission() }}元(佣金)</span
              >
            </div>
          </van-row>
        </div>
      </div>
    </van-sticky>

    <ul class="order-list mt10 pb50" v-if="type === 2">
      <li class="card" v-for="(item, key) in matches" :key="key">
        <div class="top">
          <span>{{ item.home_team }}</span>
          <span class="ml10 mr10">vs</span>
          <span>{{ item.away_team }}</span>
        </div>
        <div class="main">
          <div class="l">
            <div>
              {{ item.number.substr(8, 1) | formatWeekday }}
            </div>
            <div>
              {{ item.number.substr(9, 3) }}
            </div>
          </div>
          <div class="r">
            <table>
              <tr>
                <td>玩法</td>
                <td>选项</td>
                <td>赛果</td>
              </tr>
              <tr v-if="options[item.number].f_spf_f.length > 0">
                <td class="wid-20">胜平负</td>
                <td>
                  <span
                    v-for="(val, k) in options[item.number].f_spf_f"
                    :key="k"
                    :class="
                      handleSpfResult(
                        item.ft_home_point,
                        item.ft_away_point
                      ) === val && item.state === 13
                        ? 'red'
                        : ''
                    "
                  >
                    {{ val | formatSportsSpf }}
                    <span v-if="item.state === -3"> （1） </span
                    ><span v-else
                      >（
                      <label
                        :class="
                          project.state === 1 || project.state === 0
                            ? 'underLine'
                            : ''
                        "
                        @click="
                          handleEditRate(
                            item,
                            val,
                            resultRates[item.number].f_spf_f[val],
                            'f_spf_f'
                          )
                        "
                        >{{ resultRates[item.number].f_spf_f[val] }}</label
                      >
                      ）</span
                    >
                  </span>
                </td>
                <td class="wid-20">
                  <span v-if="item.state === -3"> 赛事无效 </span>
                  <span v-if="item.state === 13">
                    {{
                      handleSpfResult(item.ft_home_point, item.ft_away_point)
                        | formatSportsSpf
                    }}
                  </span>
                </td>
              </tr>
              <tr v-if="options[item.number].f_rqspf_f.length > 0">
                <td class="wid-20">让球 （{{ item.ft_let_point_multi }}）</td>
                <td>
                  <span
                    v-for="(val, k) in options[item.number].f_rqspf_f"
                    :key="k"
                    :class="
                      handleRqspfResult(
                        item.ft_home_point,
                        item.ft_away_point,
                        item.ft_let_point_multi
                      ) === val && item.state === 13
                        ? 'red'
                        : ''
                    "
                  >
                    {{ val | formatSportsSpf }}
                    <span v-if="item.state === -3"> （1） </span
                    ><span v-else
                      >（
                      <label
                        :class="
                          project.state === 1 || project.state === 0
                            ? 'underLine'
                            : ''
                        "
                        @click="
                          handleEditRate(
                            item,
                            val,
                            resultRates[item.number].f_rqspf_f[val],
                            'f_rqspf_f'
                          )
                        "
                        >{{ resultRates[item.number].f_rqspf_f[val] }}</label
                      >
                      ）</span
                    >
                  </span>
                </td>
                <td class="wid-20">
                  <span v-if="item.state === -3"> 赛事无效 </span>
                  <span v-if="item.state === 13">
                    {{
                      handleRqspfResult(
                        item.ft_home_point,
                        item.ft_away_point,
                        item.ft_let_point_multi
                      ) | formatSportsSpf
                    }}
                  </span>
                </td>
              </tr>
              <tr v-if="options[item.number].f_bf_f.length > 0">
                <td class="wid-20">比分</td>
                <td>
                  <span
                    v-for="(val, k) in options[item.number].f_bf_f"
                    :key="k"
                    :class="
                      handleBfResult(item.ft_home_point, item.ft_away_point) ===
                        val && item.state === 13
                        ? 'red'
                        : ''
                    "
                  >
                    {{ val | formatSportsBf }}
                    <span v-if="item.state === -3">（1）</span
                    ><span v-else
                      >（
                      <label
                        :class="
                          project.state === 1 || project.state === 0
                            ? 'underLine'
                            : ''
                        "
                        @click="
                          handleEditRate(
                            item,
                            val,
                            resultRates[item.number].f_bf_f[val],
                            'f_bf_f'
                          )
                        "
                        >{{ resultRates[item.number].f_bf_f[val] }}</label
                      >）</span
                    >
                  </span>
                </td>
                <td class="wid-20">
                  <span v-if="item.state === 13">
                    {{
                      handleBfResult(item.ft_home_point, item.ft_away_point)
                        | formatSportsBf
                    }}
                  </span>
                  <span v-if="item.state === -3"> 赛事无效 </span>
                </td>
              </tr>
              <tr v-if="options[item.number].f_zjq_f.length > 0">
                <td class="wid-20">总进球</td>
                <td>
                  <span
                    v-for="(val, k) in options[item.number].f_zjq_f"
                    :key="k"
                    :class="
                      handleZjqResult(
                        item.ft_home_point,
                        item.ft_away_point
                      ).toString() === val && item.state === 13
                        ? 'red'
                        : ''
                    "
                  >
                    {{ val | formatSportsZjq }}
                    <span v-if="item.state === -3"> （1） </span
                    ><span v-else
                      >（
                      <label
                        :class="
                          project.state === 1 || project.state === 0
                            ? 'underLine'
                            : ''
                        "
                        @click="
                          handleEditRate(
                            item,
                            val,
                            resultRates[item.number].f_zjq_f[val],
                            'f_zjq_f'
                          )
                        "
                        >{{ resultRates[item.number].f_zjq_f[val] }}</label
                      >）
                    </span>
                  </span>
                </td>
                <td class="wid-20">
                  <span v-if="item.state === 13">
                    {{
                      handleZjqResult(item.ft_home_point, item.ft_away_point)
                        | formatSportsZjq
                    }}
                  </span>
                  <span v-if="item.state === -3"> 赛事无效 </span>
                </td>
              </tr>
              <tr v-if="options[item.number].f_bqspf_f.length > 0">
                <td>半全场</td>
                <td>
                  <span
                    v-for="(val, k) in options[item.number].f_bqspf_f"
                    :key="k"
                    :class="
                      handleBqcResult(
                        item.ft_half_home_point,
                        item.ft_half_away_point,
                        item.ft_home_point,
                        item.ft_away_point
                      ) === val && item.state === 13
                        ? 'red'
                        : ''
                    "
                  >
                    {{ val | formatSportsBqc
                    }}<span v-if="item.state === -3"> （1） </span
                    ><span v-else
                      >（
                      <label
                        :class="
                          project.state === 1 || project.state === 0
                            ? 'underLine'
                            : ''
                        "
                        @click="
                          handleEditRate(
                            item,
                            val,
                            resultRates[item.number].f_bqspf_f[val],
                            'f_bqspf_f'
                          )
                        "
                        >{{ resultRates[item.number].f_bqspf_f[val] }}</label
                      >
                      ）</span
                    >
                  </span>
                </td>
                <td class="wid-20">
                  <span v-if="item.state === 13">
                    {{
                      handleBqcResult(
                        item.ft_half_home_point,
                        item.ft_half_away_point,
                        item.ft_home_point,
                        item.ft_away_point
                      ) | formatSportsBqc
                    }}
                  </span>
                  <span v-if="item.state === -3"> 赛事无效 </span>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </li>
    </ul>

    <div v-if="tickets.length > 0 && type === 1" class="child mt10">
      <div class="title">
        <p class="red tac" style="background: #fff">
          总方案金额：{{ project.bet_money }}元
        </p>
        <van-row
          type="flex"
          style="height: 44px; background: #fff"
          justify="space-between"
          align="center"
        >
          <van-col>
            <span class="ml16"
              >实票(共{{ tickets.length }}页{{ ticketCount }}张)</span
            >
          </van-col>
          <van-col span="12" class="tar">
            <span class="mr5"
              >订单尾号：{{ project.project_id.slice(-6) }}
            </span>
          </van-col>
        </van-row>
      </div>

      <div class="body pb50">
        <van-swipe
          ref="swipe"
          @change="handleChange"
          :touchable="false"
          :show-indicators="false"
        >
          <van-swipe-item v-for="(item, key) in tickets" :key="key">
            <div class="child-content">
              <div class="child-tag">
                注意：该投注单需要扫描<span>{{ item.count }}</span
                >次 <span>{{ item.bet_multi }} </span>倍
              </div>
              <div class="child-type ft14 mb10">
                玩法：{{ item.play_type | formatProjectPayType }}
              </div>
              <ul class="order-list">
                <li
                  class="card"
                  v-for="(item1, key1) in Object.keys(childOptions[key]).sort()"
                  :key="key1"
                  :class="nowDate !== item1.substr(0, 8) ? 'red' : ''"
                >
                  <div>
                    <span class="order-week">
                      {{ item1.substr(8, 1) | formatWeekday }}
                      {{ item1.substr(9, 3) }}
                    </span>
                    <span class="order-result">
                      <span
                        v-for="(val, k) in childOptions[key][item1].f_spf_f"
                        :key="k"
                      >
                        {{ val | formatSportsSpf }}（{{
                          resultRates[item1]["f_spf_f"][val]
                        }}）
                        <label>,</label>
                      </span>
                      <span
                        v-for="(val, k) in childOptions[key][item1].f_rqspf_f"
                        :key="k"
                      >
                        {{ val | formatSportsRqspf }}（{{
                          resultRates[item1]["f_rqspf_f"][val]
                        }}）
                        <label>,</label>
                      </span>
                      <span
                        v-for="(val, k) in childOptions[key][item1].f_bf_f"
                        :key="k"
                      >
                        {{ val | formatSportsBf }}（{{
                          resultRates[item1]["f_bf_f"][val]
                        }}）
                        <label>,</label>
                      </span>
                      <span
                        v-for="(val, k) in childOptions[key][item1].f_zjq_f"
                        :key="k"
                      >
                        {{ val | formatSportsZjq }}（{{
                          resultRates[item1]["f_zjq_f"][val]
                        }}）
                        <label>,</label>
                      </span>
                      <span
                        v-for="(val, k) in childOptions[key][item1].f_bqspf_f"
                        :key="k"
                      >
                        {{ val | formatSportsBqc }}（{{
                          resultRates[item1]["f_bqspf_f"][val]
                        }}）
                        <label>,</label>
                      </span>
                    </span>
                  </div>
                </li>
              </ul>

              <div class="ml40 mr40 ft12 gray0">
                <van-row class="mt10">
                  <van-col span="12">
                    串关：<span
                      v-for="(o, i) in item.pass_type.split(',')"
                      :key="i"
                    >
                      {{ o | formatProjectPassType }}
                    </span>
                  </van-col>
                  <van-col span="12"
                    >倍数：<span>{{ item.bet_multi }}</span></van-col
                  >
                </van-row>
                <van-row class="mt20">
                  <van-col span="12"
                    >金额：<span>{{ item.bet_money }}元</span></van-col
                  >
                  <van-col span="12">
                    最大奖金：¥<span>{{ max_prize.toFixed(2) }}</span>
                  </van-col>
                </van-row>
              </div>
            </div>
          </van-swipe-item>
        </van-swipe>

        <div class="nav">
          <van-icon
            class="arrow-left"
            v-show="childOptions.length > 1"
            name="arrow-left"
            @click="handleLast"
            style="font-weight: bold"
            color="#1989fa"
            size="34"
          />
          <!-- :disabled="
              !splitOrderPrint(
                Object.keys(childOptions[childIndex]).length,
                tickets[childIndex].pass_type
              )
            " -->
          <!-- <van-button type="primary" size="small" @click="handlePrint">
            第{{ childIndex + 1 }}页 点击打印<span v-show="tickets.length > 1"
              >{{ tickets[childIndex].count }}张票</span
            >
          </van-button> -->
          <van-icon
            class="arrow-right"
            v-show="childOptions.length > 1"
            name="arrow"
            @click="handleNext"
            style="font-weight: bold"
            color="#1989fa"
            size="34"
          />
        </div>
      </div>
    </div>

    <van-dialog
      v-model="editVisible"
      @confirm="handleEditSubmit"
      :title="editTitle"
      show-cancel-button
    >
      <van-field
        type="number"
        right-icon="edit"
        v-model="editText"
        placeholder="请输入"
      ></van-field>
    </van-dialog>
  </div>
</template>

<script>
import dayjs from "dayjs";
import { Decimal } from "decimal.js";
import project from "@/api/project";
export default {
  name: "detail-jczq",
  props: [
    "project",
    "matches",
    "options",
    "rates",
    "childOptions",
    "tickets",
    "type",
    "bonus_sub_tax",
    "resultRates",
  ],
  data() {
    return {
      editItem: {},
      editVisible: false,
      editTitle: "修改",
      editText: 0,
      match: {},
      childIndex: 0,
      ticketCount: 0,
      nowDate: dayjs(new Date()).format("YYYYMMDD"),
      max_prize: 0,
    };
  },
  created() {
    this.matches.forEach((o) => {
      this.match[o.number] = {
        away_team: o.away_team,
        home_team: o.home_team,
        league: o.league,
        ft_let_point_multi: o.ft_let_point_multi,
      };
    });
    this.handleCalcCount();
    this.handleJudgeLast();
    this.handleCalcMaxPrize();
  },
  methods: {
    handleToUSerDetail(val) {
      this.$router.push({
        name: "account-book-customer-detail",
        query: {
          user_id: val,
        },
      });
    },
    handleEditRate(val, v, v1, v2) {
      if (this.project.state !== 1 && this.project.state !== 0) {
        return false;
      }

      if (dayjs().isAfter(dayjs(this.project.expire_time))) {
        this.$toast("截止时间已过");
        return false;
      }
      let format = {
        type: val.type,
        play_type: v2,
        bet_match: val.number,
        bet_code: v,
        bet_rate: v1,
        pk: this.$route.query.pk,
      };

      this.editItem = format;
      this.editTitle = "修改赔率";
      this.editVisible = true;
      this.editText = v1;
    },
    handleEditSubmit() {
      if (this.project.state !== 1) {
        return false;
      }
      this.$toast("暂无权限");
      if (this.project.state !== -2) {
        return false;
      }
      project
        .editSportsRate(this.$route.query.pk, {
          type: this.editItem.type,
          play_type: this.editItem.play_type,
          bet_match: this.editItem.bet_match,
          bet_code: this.editItem.bet_code,
          bet_rate: this.editText,
        })
        .then(() => {
          this.$emit("handleInit");
        })
        .catch(() => {
          this.$emit("handleInit");
        });
    },
    // 计算佣金金额
    handleCommission() {
      // 中奖金额 - 投注金额  剩余的7%
      if (Number(this.project.bonus_money) > Number(this.project.bet_money)) {
        let temp = new Decimal(this.project.bonus_money)
          .sub(this.project.bet_money)
          .mul(0.07);
        return temp;
      } else {
        return 0;
      }
    },
    handleCalcCount() {
      let count = 0;
      this.tickets.forEach((res) => {
        count += res.count;
      });
      this.ticketCount = count;
    },
    handleChange(val) {
      this.childIndex = val;
      this.handleCalcMaxPrize(val);
      this.handleJudgeLast();
      this.handleScrollTo();
    },
    handleJudgeLast() {
      if (this.childIndex + 1 === this.tickets.length) {
        this.$emit("handleIsLast", true);
      } else {
        this.$emit("handleIsLast", false);
      }
    },
    handleScrollTo() {
      let order_list_height =
        document.getElementsByClassName("order-list")[0].clientHeight + 10;
      document.getElementsByClassName("item-detail")[0].scrollTop =
        order_list_height;
    },
    handleLast() {
      if (this.childIndex === 0) {
        this.$toast("当前第一张票");
        return;
      }
      this.$refs.swipe.prev();
    },
    handleNext() {
      if (this.childIndex + 1 === this.tickets.length) {
        this.$toast("当前最后一张票");
        return;
      }
      this.$refs.swipe.next();
    },
    handlePrint() {
      let obj = this.tickets[this.childIndex];
      obj.childIndex = this.childIndex;
      obj.sum = this.tickets.length;

      this.$emit("handlePrint", obj);
    },
    handleSpfResult(ft_home_point, ft_away_point) {
      return this.handleCompare(ft_home_point, ft_away_point);
    },

    handleRqspfResult(ft_home_point, ft_away_point, ft_let_point_multi) {
      return this.handleCompare(
        Number(ft_home_point) + Number(ft_let_point_multi),
        ft_away_point
      );
    },

    handleBfResult(ft_home_point, ft_away_point) {
      let s = ft_home_point.toString() + ft_away_point.toString();

      if (Number(ft_home_point) > Number(ft_away_point)) {
        if (
          ![
            "10",
            "20",
            "21",
            "30",
            "31",
            "32",
            "40",
            "41",
            "42",
            "50",
            "51",
            "52",
          ].includes(s)
        ) {
          s = "90";
        }
      } else if (Number(ft_home_point) < Number(ft_away_point)) {
        if (
          ![
            "01",
            "02",
            "12",
            "03",
            "13",
            "23",
            "04",
            "14",
            "24",
            "05",
            "15",
            "25",
          ].includes(s)
        ) {
          s = "09";
        }
      } else {
        if (!["00", "11", "22", "33"].includes(s)) {
          s = "99";
        }
      }
      return s;
    },

    handleZjqResult(ft_home_point, ft_away_point) {
      return Number(ft_home_point) + Number(ft_away_point) >= 7
        ? 7
        : Number(ft_home_point) + Number(ft_away_point);
    },

    handleBqcResult(
      ft_half_home_point,
      ft_half_away_point,
      ft_home_point,
      ft_away_point
    ) {
      return (
        this.handleCompare(ft_half_home_point, ft_half_away_point) +
        this.handleCompare(ft_home_point, ft_away_point)
      );
    },

    handleCompare(a, b) {
      return Number(a) - Number(b) > 0
        ? "3"
        : Number(a) - Number(b) === 0
        ? "1"
        : "0";
    },
    handleCalcMaxPrize(i = 0) {
      // 拆单每个场次只有有一种玩法 只有取最大值就可以
      // 最大赔率数组
      let self = this;
      let max_prize_array = [];
      this.max_prize = 0;

      Object.keys(this.childOptions[i]).forEach((o) => {
        let t = [];
        let obj = this.childOptions[i][o];

        Object.keys(obj).forEach((o1) => {
          if (obj[o1].length > 0) {
            t = obj[o1].map((o2) => {
              return self.resultRates[o][o1][o2];
            });
          }
        });

        max_prize_array.push(Math.max(...t));
      });

      // // 串关数
      let chuan = this.tickets[i].pass_type.split(",").map((o) => {
        return o.split("_")[0];
      });

      chuan.forEach((o) => {
        let temp = this.bonusPermutation(max_prize_array, o);

        let temp_array = temp.map((o1) => {
          return o1.reduce((x, y) => new Decimal(x).mul(y), 1);
        });

        let t = new Decimal(
          temp_array.reduce((x, y) => new Decimal(x).add(y), 0)
        )
          .mul(2)
          .toFixed(2);
        let t1 = new Decimal(t).mul(this.tickets[i].bet_multi);
        this.max_prize = new Decimal(this.max_prize).add(t1);
      });

      // max_prize

      let max_prize_temp = this.max_prize;

      if (this.matches.length >= 6) {
        if (Number(max_prize_temp) > 1000000) {
          max_prize_temp = new Decimal(1000000).mul(this.project.bet_multi);
        }
      } else if (this.matches.length >= 4) {
        if (Number(max_prize_temp) > 500000) {
          max_prize_temp = new Decimal(500000).mul(this.project.bet_multi);
        }
      } else if (this.matches.length >= 2) {
        if (Number(max_prize_temp) > 200000) {
          max_prize_temp = new Decimal(200000).mul(this.project.bet_multi);
        }
      }

      this.max_prize = max_prize_temp;
    },
  },
};
</script>

<style lang="stylus" scoped>
#detail-jczq {
  .info-sticky{
    background:#f0f0f0;
    padding-top: 10px;
    .info {
      padding: 10px;
      background: #ffffff;
      font-size: 14px;
      color: #888888;

      .bonus {
        color: #f00;
      }

      div {
        padding: 2px;
      }
    }
  }
  .order-list {
    .card {
      padding: 10px;
      background: #ffffff;
      margin-bottom: 10px;
      border-radius: 5px;

      .top {
        font-size: 14px;
        text-align: center;
        margin-bottom: 10px;
      }

      .main {
        display: flex;
        flex-direction: row;

        .l {
          padding-right: 5px;
          font-size: 12px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          color: #888888;

          div {
            padding: 2px 0;
          }
        }

        .r {
          flex: 1;
        }
      }
    }

    .card.red{
      span{
        color:red!important;
      }
    }

    table {
      width: 100%;
      text-align: center;
      border-collapse: collapse;
      font-size: 12px;

      tr {
        height: 20px;
        border: 1px solid #ddd;
      }

      td {
        border: 1px solid #ddd;
        padding: 0 4px;
      }

      .red {
        color: red;
      }
    }
  }

  .child {
    .body {
      position: relative;
      background: #fff;
    }
    .child-content {
      position: relative;
      background: #fff;

      .child-tag {
        position: absolute;
        top: 10px;
        left: 0;
        right: 0;
        text-align: center;
        color: red;

        span {
          font-weight: bold;
          font-size: 20px;
        }
      }

      .child-type{
        padding: 45px 40px 0;
      }

      ul {
        background: #fff;
        padding: 0px 30px 0;

        li {
          margin-bottom: 0px !important;
          >div {
            font-size: 12px;
            display: flex;
            align-items: center;
            .order-week {
              width: 50px;
              margin-right: 10px;
              color: #888;
            }
            .order-result {
              flex: 1;
              span {
                &:last-child {
                  label {
                    &:last-child {
                      display: none;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .nav {
      display: flex;
      align-items: center;
      height: 50px;
      justify-content: space-evenly;
      background: #fff;
      .arrow-left {
        position: absolute;
        top: 50%;
        left: 10px;
        margin-top: -12px;
      }
      .arrow-right {
        position: absolute;
        top: 50%;
        right: 10px;
        margin-top: -12px;
      }
    }
  }
}
</style>
