<template>
  <div id="project-number-detail">
    <c-nav-bar title="方案详情"></c-nav-bar>
    <div class="content">
      <div class="item">
        <van-tabs
          v-model="tabIndex"
          title-active-color="#38A6F3"
          color="#38A6F3"
        >
          <van-tab title="拆单详情">
            <div class="item-detail" v-if="project.project_id">
              <detail-dlt
                v-if="project.type === 'dlt'"
                :project="project"
                :tickets="tickets"
                :bonus_sub_tax="bonus_sub_tax"
                @handlePrint="handlePrint"
                :type="1"
              ></detail-dlt>
              <detail-qxc
                v-if="project.type === 'qxc'"
                :project="project"
                :tickets="tickets"
                :bonus_sub_tax="bonus_sub_tax"
                @handlePrint="handlePrint"
                :type="1"
              ></detail-qxc>
              <detail-pls
                v-if="project.type === 'pls'"
                :project="project"
                :tickets="tickets"
                :bonus_sub_tax="bonus_sub_tax"
                @handlePrint="handlePrint"
                :type="1"
              ></detail-pls>
              <detail-plw
                v-if="project.type === 'plw'"
                :project="project"
                :tickets="tickets"
                :bonus_sub_tax="bonus_sub_tax"
                @handlePrint="handlePrint"
                :type="1"
              ></detail-plw>
              <detail-sfc
                v-if="project.type === 'sfc14' || project.type === 'sfc9'"
                :project="project"
                :matches="matches"
                :tickets="tickets"
                :bonus_sub_tax="bonus_sub_tax"
                @handlePrint="handlePrint"
                :type="1"
              ></detail-sfc>
              <detail-ssq
                v-if="project.type === 'ssq'"
                :project="project"
                :tickets="tickets"
                :bonus_sub_tax="bonus_sub_tax"
                @handlePrint="handlePrint"
                :type="1"
              ></detail-ssq>
              <detail-qlc
                v-if="project.type === 'qlc'"
                :project="project"
                :tickets="tickets"
                :bonus_sub_tax="bonus_sub_tax"
                @handlePrint="handlePrint"
                :type="1"
              ></detail-qlc>
              <detail-sd
                v-if="project.type === 'sd'"
                :project="project"
                :tickets="tickets"
                :bonus_sub_tax="bonus_sub_tax"
                @handlePrint="handlePrint"
                :type="1"
              ></detail-sd>
              <detail-klb
                v-if="project.type === 'klb'"
                :project="project"
                :tickets="tickets"
                :bonus_sub_tax="bonus_sub_tax"
                @handlePrint="handlePrint"
                :type="1"
              ></detail-klb>
            </div>
          </van-tab>
          <van-tab title="方案详情">
            <div class="item-detail" v-if="project.project_id">
              <detail-dlt
                v-if="project.type === 'dlt'"
                :project="project"
                :tickets="tickets"
                :bonus_sub_tax="bonus_sub_tax"
                @handlePrint="handlePrint"
                :type="2"
              ></detail-dlt>
              <detail-qxc
                v-if="project.type === 'qxc'"
                :project="project"
                :tickets="tickets"
                :bonus_sub_tax="bonus_sub_tax"
                @handlePrint="handlePrint"
                :type="2"
              ></detail-qxc>
              <detail-pls
                v-if="project.type === 'pls'"
                :project="project"
                :tickets="tickets"
                :bonus_sub_tax="bonus_sub_tax"
                @handlePrint="handlePrint"
                :type="2"
              ></detail-pls>
              <detail-plw
                v-if="project.type === 'plw'"
                :project="project"
                :tickets="tickets"
                :bonus_sub_tax="bonus_sub_tax"
                @handlePrint="handlePrint"
                :type="2"
              ></detail-plw>
              <detail-sfc
                v-if="project.type === 'sfc14' || project.type === 'sfc9'"
                :project="project"
                :matches="matches"
                :tickets="tickets"
                :bonus_sub_tax="bonus_sub_tax"
                @handlePrint="handlePrint"
                :type="2"
              ></detail-sfc>
              <detail-ssq
                v-if="project.type === 'ssq'"
                :project="project"
                :tickets="tickets"
                :bonus_sub_tax="bonus_sub_tax"
                @handlePrint="handlePrint"
                :type="2"
              ></detail-ssq>
              <detail-qlc
                v-if="project.type === 'qlc'"
                :project="project"
                :tickets="tickets"
                :bonus_sub_tax="bonus_sub_tax"
                @handlePrint="handlePrint"
                :type="2"
              ></detail-qlc>
              <detail-sd
                v-if="project.type === 'sd'"
                :project="project"
                :tickets="tickets"
                :bonus_sub_tax="bonus_sub_tax"
                @handlePrint="handlePrint"
                :type="2"
              ></detail-sd>
              <detail-klb
                v-if="project.type === 'klb'"
                :project="project"
                :tickets="tickets"
                :bonus_sub_tax="bonus_sub_tax"
                @handlePrint="handlePrint"
                :type="2"
              ></detail-klb>
            </div>
          </van-tab>
          <van-tab title="实票照片">
            <div v-if="project.ticket_img_urls">
              <div
                v-if="
                  project.ticket_img_urls.length > 0 && againUpload === false
                "
              >
                <van-swipe class="ticket-image" :autoplay="3000">
                  <van-swipe-item
                    class="tac"
                    v-for="(o, i) in project.ticket_img_urls"
                    :key="i"
                  >
                    <img style="max-height: 60vh; max-width: 100%" :src="o" />
                  </van-swipe-item>
                </van-swipe>
                <!-- <van-button
                  class="detail-upload-btn"
                  size="small"
                  type="info"
                  block
                  @click="againUpload = true"
                  >重新上传</van-button
                > -->
              </div>

              <div
                class="detail-upload mt10"
                v-if="project.ticket_img_urls.length === 0 || againUpload"
              >
                <div class="header tal mb10">
                  <van-uploader
                    :after-read="handleUploadImage"
                    v-model="ticketsImage"
                    :max-count="4"
                  >
                  </van-uploader>
                </div>

                <van-button
                  class="detail-upload-btn"
                  size="small"
                  type="info"
                  block
                  @click="handlUpload()"
                  >上传</van-button
                >
              </div>
            </div>
          </van-tab>
          <van-tab title="合买详情" v-if="project.is_groupon === 1">
            <div>
              <p class="pt10 pb10 pl5">合买信息</p>
              <table class="hm_table" border="1" cellspacing="0">
                <tbody>
                  <tr>
                    <td>
                      共<label class="red">{{ grouponPorject.quantity }}</label
                      >份<br />每份<label class="red">{{
                        grouponPorject.per_money
                      }}</label
                      >元
                    </td>
                    <td>
                      <div class="tac">
                        发起人<br />盈利奖励
                        <span class="red">{{ grouponPorject.rate * 100 }}</span>
                        %
                      </div>
                    </td>
                    <td>
                      <div
                        :class="{
                          red:
                            grouponPorject.state === 1 &&
                            (grouponPorject.project_state === 3 ||
                              grouponPorject.project_state === 4),
                        }"
                      >
                        <span>{{
                          grouponPorject.state | formatGrouponState
                        }}</span>
                        <span v-if="grouponPorject.state !== -1"
                          >/
                          {{
                            grouponPorject.project_state
                              | formatGrouponProjectState
                          }}</span
                        >
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <p class="pt10 pb10 pl5">参与列表</p>
              <table class="hm_table" border="1" cellspacing="0">
                <thead>
                  <tr style="color: #666">
                    <td>备注名</td>
                    <td>参与金额</td>
                    <td>中奖金额</td>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(o, i) in grouponJoinPerson" :key="i">
                    <td>{{ o.nickname }}</td>
                    <td>{{ handleGetJoinMoney(o) }}元</td>
                    <td class="red">{{ o.bonus_money }}元</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </van-tab>
        </van-tabs>
      </div>
    </div>
    <!-- <p-tabbar
      ref="tabbar"
      :type="type"
      :state="project.state"
      :ticketImage="project.ticket_img_urls"
    ></p-tabbar> -->
  </div>
</template>

<script>
import CNavBar from "@/components/CNavBar";
// import PTabbar from "@/components/PTabbar";

import DetailDlt from "@/views/project/detail/Dlt";
import DetailPls from "@/views/project/detail/Pls";
import DetailPlw from "@/views/project/detail/Plw";
import DetailQxc from "@/views/project/detail/Qxc";
import DetailSfc from "@/views/project/detail/Sfc";
import DetailSsq from "@/views/project/detail/Ssq";
import DetailQlc from "@/views/project/detail/Qlc";
import DetailSd from "@/views/project/detail/Sd";
import DetailKlb from "@/views/project/detail/Klb";
import project from "@/api/project";
// import groupon from "@/api/groupon";

import { Decimal } from "decimal.js";

export default {
  name: "project-number-detail",
  data() {
    return {
      tabIndex: 0,
      project: {},
      tickets: [],
      matches: [],
      type: "dlt",
      pk: null,
      ticketsImage: [],
      bonus_sub_tax: 0,
      againUpload: false,
      grouponPorject: {},
      grouponJoinPerson: [],
    };
  },
  components: {
    CNavBar,
    // PTabbar,
    DetailDlt,
    DetailPls,
    DetailPlw,
    DetailQxc,
    DetailSfc,
    DetailSsq,
    DetailQlc,
    DetailSd,
    DetailKlb,
  },
  created() {
    this.type = this.$route.query.type;
    this.pk = this.$route.query.pk;
    this.handleInit();
    this.tabIndex = this.$route.query.from === "ticket" ? 2 : 0;
  },
  methods: {
    handleGetJoinMoney(o) {
      return new Decimal(o.quantity).mul(o.per_money).toFixed(2);
    },
    // handleIsLast(obj) {
    //   this.$refs.tabbar.isLast = obj;
    // },
    handlePrint(obj) {
      let assembleData = {
        projectID: obj.project_id,
        count: obj.sum.toString(),
        strBetMoney: obj.bet_money,
        index: obj.childIndex + 1,
        strBetMulti: obj.bet_multi.toString(),
        strBetCodes: obj.bet_codes,
        strType: obj.type,
        strPlayType: obj.play_type,
      };
      try {
        // eslint-disable-next-line
        // android.ticketOut(assembleData);
        console.log(JSON.stringify(assembleData));
        // android.ticketOutFoot(JSON.stringify(assembleData));
      } catch {
        this.$toast("此次弹框说明，该设备是非安卓平台");
      }
    },
    handleInit() {
      let pk = this.$route.query.pk;
      project.getNumber(pk).then((res) => {
        console.log("res.results", res.results);
        console.log("res.results", res.results.project);
        this.project = res.results.project;
        this.tickets = res.results.tickets.sort((a, b) => {
          return a.pk - b.pk;
        });
        this.matches = res.results.matches;
        this.bonus_sub_tax = new Decimal(this.project.bonus_money).sub(
          new Decimal(this.project.tax)
        );

        this.ticketsImage = res.results.project.ticket_img_urls.map((o) => {
          return {
            url: o,
            status: "success",
          };
        });

        // 合买订单 另外请求合买信息
        if (this.project.is_groupon === 1) {
          this.hanldeGetGroupon();
        }
      });
      // .catch((res) => {
      //   this.$refs.recharge.handleShow(res.code, res.message);
      // });
    },
    // hanldeGetGroupon() {
    //   groupon
    //     .getProject({
    //       project_id: this.project.project_id,
    //     })
    //     .then((res) => {
    //       this.grouponPorject = res.results;
    //       groupon
    //         .listUser({
    //           groupon_project_id: res.results.groupon_project_id,
    //         })
    //         .then((res1) => {
    //           this.grouponJoinPerson = res1.results;
    //         });
    //     });
    // },

    handlUpload() {
      let ticket_img_urls = this.ticketsImage.map((o) => {
        return o.url;
      });
      if (ticket_img_urls.length < 1) {
        this.$toast("还未上传票照");
        return;
      }
      let t = ticket_img_urls.filter((o) => {
        return o;
      });
      if (t.length === 0) {
        this.$toast("图片不能为空，请重新上传");
        return false;
      }
      this.$dialog
        .confirm({
          message: "是否确认上传？",
        })
        .then(() => {
          this.handleSucceed().then(() => {
            project
              .upload(this.pk, {
                ticket_img_urls: ticket_img_urls,
              })
              .then(() => {
                this.handleInit();
                this.$toast("票照上传成功");
                this.$router.push({ name: "project-list", hash: "#0" });
              })
              .catch((err) => {
                this.$toast({
                  message: err.message,
                });
              });
          });
        })
        .catch(() => {});
    },
    handleSucceed() {
      return new Promise((resolve) => {
        project
          .printNumberSucceed(this.pk)
          .then(() => {
            resolve();
          })
          .catch((err) => {
            this.$toast({
              message: err.message,
            });
          });
      });
    },
  },
};
</script>

<style lang="stylus" scoped>
#project-number-detail {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .content {
    height: clac(100% - 46px);
    flex: 1;
    position: absolute;
    left: 0;
    right: 0;
    top: 46px;
    bottom: 44px;


    .item-detail{
      overflow auto
    }
  }

  .ticket-image{
    width 90%
    margin 20px auto
  }

  .gray {
    color: #666;
    padding: 0 5px;
  }

  .detail-upload {
    background #fff
    padding: 20px;
    margin-bottom: 10px;

  }
  .detail-upload-btn {
      width: 172px;
      margin: 0 auto;
    }
}
.hm_table{
  background: #fff;
  width: 100%;
  border: 1px solid #ececec;
  tr,td {
    border: 1px solid #ececec;
  }
  td{
    padding: 10px 0;
    width: 33.3%;
    text-align: center;
  }

}
</style>
