<template>
  <div id="detail-qxc">
    <van-sticky v-if="type === 2" :offset-top="90">
      <div class="info-sticky">
        <div class="info">
          <van-row type="flex" justify="space-between">
            <van-col
              style="color: rgb(46, 91, 253)"
              @click="handleToUSerDetail(project.user_id)"
              >用户昵称：{{ project.user.nickname
              }}<span v-if="project.user.remark"
                >（{{ project.user.remark }}）</span
              ></van-col
            >
            <van-col></van-col>
          </van-row>
          <van-row type="flex" justify="space-between">
            <van-col>{{ project.project_id }}</van-col>
            <van-col>{{ project.state | formatProjectState }}</van-col>
          </van-row>
          <van-row type="flex" justify="space-between">
            <van-col>
              <div>
                {{ project.type | formatProjectType }} 第{{
                  project.bet_match
                }}期
              </div>
            </van-col>
            <van-col>
              <div>{{ project.bet_multi }}倍 ¥{{ project.bet_money }}元</div>
            </van-col>
            <van-col v-if="project.state >= 3">
              <div class="bonus">中奖：¥{{ project.bonus_money }}元</div>
            </van-col>
          </van-row>
          <van-row type="flex" justify="space-between">
            <van-col>
              下单时间：{{ project.created_at | formatDateTime }}
            </van-col>
            <van-col>
              <span v-if="project.state >= 0">出票时间：</span>
              <span v-else>撤单时间：</span>
              {{ project.handled_at | formatDateTime }}
            </van-col>
          </van-row>
          <van-row v-if="project.state >= 3 && bonus_sub_tax > 0">
            <div class="bonus">
              奖金：¥{{ project.bonus_money }}元 - ¥{{ project.tax }}元(税) =
              ¥{{ bonus_sub_tax }}元
            </div>
          </van-row>
        </div>
      </div>
    </van-sticky>

    <div
      class="mt10"
      style="background: #fff"
      v-if="type === 2 && prizeCodeS.length > 0"
    >
      <p class="ml15 pt10 ft18">开奖号码</p>
      <ul class="order-list">
        <li>
          <div class="detail cb">
            <article
              class="dis-flex-center mr5"
              v-for="(o, i) in prizeCodeS[0].prize"
              :key="i"
            >
              <div class="single">
                <span
                  class="number"
                  :class="{
                    red: i !== 6,
                    blue: i === 6,
                  }"
                  >{{ o }}</span
                >
              </div>
            </article>
          </div>
        </li>
      </ul>
    </div>

    <ul class="order-list mt10 pb50" v-if="type === 2">
      <li class="mb10" v-for="(item, i) in project.bet_items" :key="i">
        <div
          class="detail cb"
          :class="item.play_type !== '101' ? 'dis-flex-direction' : ''"
        >
          <article
            class="dis-flex-center"
            :class="item.play_type === '101' ? 'mr5' : ''"
            v-for="(v, k) in item.arrSelected"
            :key="k"
          >
            <div class="ft12 gray6 mr5" v-if="item.play_type !== '101'">
              <span v-if="k === 0">第一位</span>
              <span v-if="k === 1">第二位</span>
              <span v-if="k === 2">第三位</span>
              <span v-if="k === 3">第四位</span>
              <span v-if="k === 4">第五位</span>
              <span v-if="k === 5">第六位</span>
              <span v-if="k === 6">第七位</span>
            </div>

            <div class="single" v-for="(m, n) in v" :key="k * 100 + n">
              <span
                class="number"
                :class="{
                  red: k !== 6,
                  blue: k === 6,
                }"
              >
                {{ m }}
                <label v-if="prizeCodeS.length > 0">
                  <van-icon
                    v-show="handleIsSelected(m, k)"
                    class="icon"
                    name="success"
                  />
                </label>
              </span>
            </div>
          </article>
        </div>

        <div class="info mt10">
          {{ [item.type, item.play_type] | formatProjectPlayType }}
          {{ item.bet_money / 2 }}注
        </div>
      </li>
    </ul>

    <div class="child mt10" v-if="type === 1">
      <p class="red tac" style="background: #fff">
        总方案金额：{{ project.bet_money }}元
      </p>
      <div class="title">
        <van-row
          type="flex"
          style="height: 44px; background: #fff"
          justify="space-between"
          align="center"
        >
          <van-col>
            <span class="ml16"
              >实票（共{{ actualTickets.length }}页{{
                handleCalcCount()
              }}张）</span
            >
          </van-col>
          <van-col span="12" class="tar">
            <span class="mr5"
              >订单尾号：{{ project.project_id.slice(-6) }}
            </span>
          </van-col>
        </van-row>
      </div>

      <div class="body mt10 pb50">
        <van-swipe
          ref="swipe"
          @change="handleChange"
          :touchable="false"
          :show-indicators="false"
        >
          <van-swipe-item v-for="(item, key) in actualTickets" :key="key">
            <div class="child-content">
              <ul class="order-list">
                <li class="mb10" v-for="(item1, key1) in item" :key="key1">
                  <div class="child-tag">
                    注意：该投注单需要出票
                    <span> {{ actualTicketsDetail[key].count }} </span>次<span>
                      {{ actualTicketsDetail[key].bet_multi }} </span
                    >倍
                  </div>
                  <div
                    class="detail cb"
                    :class="item1.type !== '101' ? 'dis-flex-direction' : ''"
                  >
                    <span
                      class="ball-number red-color"
                      v-for="(item2, key2) in item1.value.before"
                      :key="key2"
                    >
                      <span v-if="item1.type !== '101'">
                        <span class="mr5" v-if="key2 === 0">第一位</span>
                        <span class="mr5" v-if="key2 === 1">第二位</span>
                        <span class="mr5" v-if="key2 === 2">第三位</span>
                        <span class="mr5" v-if="key2 === 3">第四位</span>
                        <span class="mr5" v-if="key2 === 4">第五位</span>
                        <span class="mr5" v-if="key2 === 5">第六位</span>
                      </span>

                      {{ item2.join(" ") }}</span
                    >
                    <span
                      class="ball-number blue-color"
                      v-for="(item2, key2) in item1.value.after"
                      :key="key2"
                      ><span
                        class="mr5"
                        v-if="key2 === 0 && item1.type !== '101'"
                        >第七位</span
                      >{{ item2.join(" ") }}</span
                    >
                  </div>
                  <div class="info mt10">
                    {{ ["qxc", item1.type] | formatProjectPlayType }}
                  </div>
                </li>
              </ul>
            </div>
          </van-swipe-item>
        </van-swipe>

        <div class="nav">
          <van-icon
            class="arrow-left"
            v-show="actualTickets.length > 1"
            name="arrow-left"
            @click="handleLast"
            style="font-weight: bold"
            color="#1989fa"
            size="34"
          />
          <van-button v-show="false" type="primary" size="small"
            >第{{ childIndex + 1 }}页 点击打印<span
              v-show="actualTickets.length > 1"
              >{{ actualTicketsDetail[childIndex].count }}张票</span
            >
          </van-button>
          <van-icon
            class="arrow-right"
            v-show="actualTickets.length > 1"
            name="arrow"
            @click="handleNext"
            style="font-weight: bold"
            color="#1989fa"
            size="34"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import issue from "@/api/issue";
export default {
  name: "detail-qxc",
  props: ["project", "tickets", "type", "bonus_sub_tax"],
  data() {
    return {
      actualTickets: [],
      actualTicketsDetail: [],
      prizeCodeS: [],
      childIndex: 0,
    };
  },
  created() {
    this.handleFormatArrSelected(this.project.bet_items, "father");
    this.handleFormatArrSelected(this.tickets, "child");
    this.handleActualTickets(this.tickets);
    this.handleJudgeLast();
  },
  methods: {
    handleToUSerDetail(val) {
      this.$router.push({
        name: "account-book-customer-detail",
        query: {
          user_id: val,
        },
      });
    },
    handleCalcCount() {
      return this.actualTicketsDetail
        .map((o) => {
          return o.count;
        })
        .reduce((x, y) => x + y, 0);
    },
    handleActualTickets(data) {
      data = data.sort((a, b) => {
        return a.index - b.index;
      });

      let type_array = {
        101: [],
        102: [],
      };

      data.forEach((o) => {
        let temp = {
          before: [],
          after: [],
        };
        o.arrSelected.forEach((o, i) => {
          if (i <= 5) {
            temp["before"].push(o);
          } else {
            temp["after"].push(o);
          }
        });
        type_array[o.play_type].push({
          type: o.play_type,
          value: temp,
          bet_money: o.bet_money,
          project_id: o.project_id,
          bet_codes: o.bet_codes,
        });
      });

      this.actualTickets = [];
      this.actualTicketsDetail = [];
      let actualTickets = [];

      Object.keys(type_array).forEach((o) => {
        let temp = [];
        if (o === "101") {
          type_array[o].forEach((o1, i) => {
            if (i % 5 === 0) {
              temp.push(type_array[o].slice(i, i + 5));
            }
          });
        } else {
          temp = type_array[o].map((o1) => {
            return [o1];
          });
        }
        actualTickets = actualTickets.concat(temp);
      });

      let over50Count = Math.ceil(this.project.bet_multi / 50);
      actualTickets.forEach((o) => {
        let t = this.project.bet_multi % 50;
        let b = o
          .map((o1) => {
            return Number(o1.bet_money);
          })
          .reduce((x, y) => x + y, 0);
        if (over50Count > 1) {
          var i = 0;
          while (i < 2) {
            if (i === 0) {
              this.actualTicketsDetail.push({
                count: parseInt(over50Count) - 1,
                bet_multi: 50,
                bet_money: b * 50,
              });
            } else {
              this.actualTicketsDetail.push({
                count: 1,
                bet_multi: t,
                bet_money: b * t,
              });
            }
            this.actualTickets.push(o);
            i++;
          }
        } else {
          this.actualTickets.push(o);
          this.actualTicketsDetail.push({
            count: 1,
            bet_multi: t,
            bet_money: b * t,
          });
        }
      });
    },
    handleIsSelected(m, k) {
      return m === this.prizeCodeS[0].prize[k];
    },
    handleChange(val) {
      this.childIndex = val;
      this.handleJudgeLast();
      this.handleScrollTo();
    },
    handleJudgeLast() {
      if (this.childIndex + 1 === this.actualTickets.length) {
        this.$emit("handleIsLast", true);
      } else {
        this.$emit("handleIsLast", false);
      }
    },
    handleScrollTo() {
      let order_list_height =
        document.getElementsByClassName("order-list")[0].clientHeight + 10;
      document.getElementsByClassName("item-detail")[0].scrollTop =
        order_list_height;
    },
    handleLast() {
      if (this.childIndex === 0) {
        this.$toast("当前第一张票");
        return;
      }
      this.$refs.swipe.prev();
    },
    handleNext() {
      if (this.childIndex + 1 === this.actualTickets.length) {
        this.$toast("当前最后一张票");
        return;
      }
      this.$refs.swipe.next();
    },
    handlePrint() {
      let obj = this.tickets[this.childIndex];
      obj.bet_multi = this.project.bet_multi;
      obj.sum = this.tickets.length;
      obj.childIndex = this.childIndex;
      this.$emit("handlePrint", this.tickets[this.childIndex]);
    },
    handleFormatArrSelected(obj, type) {
      let prizes = {};
      obj.forEach((item) => {
        prizes[item.number] = item.type;
        item.arrSelected = [];
        let temp = item.bet_codes.split(",").map((o) => {
          return o.split("|");
        });
        item.arrSelected = item.arrSelected.concat(temp);
      });

      if (type === "father") {
        this.prizeCodeS = [];
        for (const item in prizes) {
          issue
            .get({ type: prizes[item], number: item })
            .then((res) => {
              if (res.results.prize_code) {
                this.prizeCodeS.push({
                  number: res.results.number,
                  prize: res.results.prize_code.split(","),
                });
              }
            })
            .catch(() => {});
        }
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
#detail-qxc {
  .info-sticky{
    background:#f0f0f0;
    padding-top: 10px;
    .info {
      padding: 10px;
      background: #ffffff;
      font-size: 14px;
      color: #888888;

      .bonus {
        color: #f00;
      }

      div {
        padding: 2px;
      }
    }
  }

  .order-list {
    font-size: 12px;

    li {
      background: #fff;
      margin: 0px auto;
      border-radius: 2px;
      align-items: center;
      padding: 10px;
      border-bottom 1px dashed #ececec
      &:last-child{
        border-bottom none
      }

      .detail {
        flex: 1;
        width: 100%;
        display: flex;
        flex-wrap: wrap;

        .single {
          height: 40px;
          position: relative;
        }

        .divider {
          &:after {
            content: '';
            position: absolute;
            top: 5px;
            right: 0;
            width: 1px;
            height: 30px;
            background: #ddd;
          }
        }

        .number {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          background: #ffffff;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-left: 5px;
          margin-right: 5px;
          font-size: 14px;
          border: 1px solid #ddd;
          position: relative;
          margin-top: 5px;
          label {
            position: absolute;
            bottom: -17px;
            color: #f00;
          }
        }

        .ball-number {
          margin 0 3px;
        }

        .red {
          background: red;
          color: #ffffff;
          border: none;
        }

        .blue {
          background: #5a8ef5;
          color: #ffffff;
          border: none;
        }

        .dan-text {
          transform: scale(0.7);
        }
      }

      .info {
        color: #666;
      }
    }
  }

  .child {
    .body {
      position: relative;
      background: #fff;
    }

    .child-content {
      position: relative;
      background: #fff;

      .child-tag {
        position: absolute;
        top: 10px;
        left: 0;
        right: 0;
        text-align: center;
        color: red;

        span {
          font-weight: bold;
          font-size: 20px;
        }
      }

      ul {
        background: #fff;
        padding: 42px 35px 0;

        li {
          font-size: 14px;
          margin-bottom: 0px !important;
        }
      }
    }

    .nav {
      display: flex;
      align-items: center;
      height: 50px;
      justify-content: space-evenly;
      background: #fff;
      .arrow-left {
        position: absolute;
        top: 50%;
        left: 10px;
        margin-top: -12px;
      }
      .arrow-right {
        position: absolute;
        top: 50%;
        right: 10px;
        margin-top: -12px;
      }
    }
  }
}
</style>
