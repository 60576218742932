<template>
  <div id="detail-ssq">
    <van-sticky v-if="type === 2" :offset-top="90">
      <div class="info-sticky">
        <div class="info">
          <van-row type="flex" justify="space-between">
            <van-col
              style="color: rgb(46, 91, 253)"
              @click="handleToUSerDetail(project.user_id)"
              >用户昵称：{{ project.user.nickname
              }}<span v-if="project.user.remark"
                >（{{ project.user.remark }}）</span
              ></van-col
            >
            <van-col></van-col>
          </van-row>
          <van-row type="flex" justify="space-between">
            <van-col>{{ project.project_id }}</van-col>
            <van-col>{{ project.state | formatProjectState }}</van-col>
          </van-row>
          <van-row type="flex" justify="space-between">
            <van-col>
              <div>
                {{ project.type | formatProjectType }} 第{{
                  project.bet_match
                }}期
              </div>
            </van-col>
            <van-col>
              <div>{{ project.bet_multi }}倍 ¥{{ project.bet_money }}元</div>
            </van-col>
            <van-col v-if="project.state >= 3">
              <div class="bonus">中奖：¥{{ project.bonus_money }}元</div>
            </van-col>
          </van-row>
          <van-row type="flex" justify="space-between">
            <van-col>
              下单时间：{{ project.created_at | formatDateTime }}
            </van-col>
            <van-col>
              <span v-if="project.state >= 0">出票时间：</span>
              <span v-else>撤单时间：</span>
              {{ project.handled_at | formatDateTime }}
            </van-col>
          </van-row>
          <van-row v-if="project.state >= 3 && bonus_sub_tax > 0">
            <div class="bonus">
              奖金：¥{{ project.bonus_money }}元 - ¥{{ project.tax }}元(税) =
              ¥{{ bonus_sub_tax }}元
            </div>
          </van-row>
        </div>
      </div>
    </van-sticky>

    <div
      class="mt10"
      style="background: #fff"
      v-if="type === 2 && prizeCodeS.length > 0"
    >
      <p class="ml15 pt10 ft18">开奖号码</p>
      <ul class="order-list">
        <li>
          <div class="detail cb">
            <div v-for="(o, i) in prizeCodeS[0].prize" :key="i">
              <span class="ball" :class="i < 6 ? 'red' : 'blue'">
                {{ o }}
              </span>
            </div>
          </div>
        </li>
      </ul>
    </div>

    <ul class="order-list mt10 pb50" v-if="type === 2">
      <li v-for="(item, key) in project.bet_items" :key="key">
        <div class="detail">
          <div v-for="(v, k) in item.arrSelected" :key="k">
            <span
              class="ball"
              :class="{
                red: v.type === 'rt' || v.type === 'rd',
                blue: v.type === 'bt' || v.type === 'bd',
                dan: v.type === 'rd' || v.type === 'bd',
              }"
            >
              {{ v.number
              }}<span class="dan-text" v-if="v.type === 'rd' || v.type === 'bd'"
                >胆</span
              >

              <label v-if="prizeCodeS.length > 0">
                <van-icon
                  v-show="handleIsSelected(v.type, v.number)"
                  class="icon"
                  name="success"
                />
              </label>
            </span>
          </div>
        </div>
        <div class="info mt10">
          {{ [item.type, item.play_type] | formatProjectPlayType }}
          <!-- {{ item.bet_money / 2 }}注 -->
        </div>
      </li>
    </ul>

    <div class="child mt10" v-if="type === 1">
      <p class="red tac" style="background: #fff">
        总方案金额：{{ project.bet_money }}元
      </p>
      <div class="title">
        <van-row
          type="flex"
          style="height: 44px; background: #fff"
          justify="space-between"
          align="center"
        >
          <van-col>
            <span class="ml16"
              >实票（共{{ actualTickets.length }}页{{
                handleCalcCount()
              }}张）</span
            >
          </van-col>
          <van-col span="12" class="tar">
            <span class="mr5"
              >订单尾号：{{ project.project_id.slice(-6) }}
            </span>
          </van-col>
        </van-row>
      </div>

      <div class="body mt10">
        <van-swipe
          ref="swipe"
          @change="handleChange"
          :touchable="false"
          :show-indicators="false"
        >
          <van-swipe-item
            v-for="(item, key) in actualTickets"
            :key="key"
            touchable="true"
          >
            <div class="child-content">
              <ul class="order-list">
                <li v-for="(item1, key1) in item" :key="key1">
                  <div class="child-tag">
                    注意：该投注单需要扫描
                    <span> {{ actualTicketsDetail[key].count }} </span>次<span>
                      {{ actualTicketsDetail[key].bet_multi }} </span
                    >倍
                  </div>

                  <div class="detail">
                    <span v-show="item1.value.rd.length > 0" class="ball-number"
                      >[</span
                    >
                    <template>
                      <span
                        v-show="item1.value.rd.length > 0"
                        class="ball-number red-color"
                        v-for="(item2, key2) in item1.value.rd"
                        :key="key2"
                        >{{ item2 }}</span
                      >
                    </template>
                    <span v-show="item1.value.rd.length > 0" class="ball-number"
                      >]</span
                    >

                    <template>
                      <span
                        v-show="item1.value.rt.length > 0"
                        class="ball-number red-color"
                        v-for="(item2, key2) in item1.value.rt"
                        :key="key2 + '11'"
                        >{{ item2 }}</span
                      >
                    </template>

                    <span class="ball-number">,</span>

                    <span v-show="item1.value.bd.length > 0" class="ball-number"
                      >[</span
                    >
                    <template>
                      <span
                        v-show="item1.value.bd.length > 0"
                        class="ball-number blue-color"
                        v-for="(item2, key2) in item1.value.bd"
                        :key="key2 + '22'"
                        >{{ item2 }}</span
                      >
                    </template>
                    <span v-show="item1.value.bd.length > 0" class="ball-number"
                      >]</span
                    >

                    <template>
                      <span
                        v-show="item1.value.bt.length > 0"
                        class="ball-number blue-color"
                        v-for="(item2, key2) in item1.value.bt"
                        :key="key2 + '33'"
                        >{{ item2 }}</span
                      >
                    </template>
                  </div>
                  <div class="info mt10">
                    {{ ["ssq", item1.type] | formatProjectPlayType }}
                  </div>
                </li>
              </ul>
            </div>
          </van-swipe-item>
        </van-swipe>

        <div class="nav">
          <van-icon
            class="arrow-left"
            v-show="actualTickets.length > 1"
            name="arrow-left"
            @click="handleLast"
            style="font-weight: bold"
            color="#1989fa"
            size="34"
          />
          <van-button
            type="primary"
            size="small"
            @click="handlePrint"
            :disabled="actualTickets[childIndex][0].type !== '901'"
            >第{{ childIndex + 1 }}页
            <span v-show="actualTickets.length > 1"
              >{{ actualTicketsDetail[childIndex].count }}张票</span
            >
          </van-button>
          <van-icon
            class="arrow-right"
            v-show="actualTickets.length > 1"
            name="arrow"
            @click="handleNext"
            style="font-weight: bold"
            color="#1989fa"
            size="34"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import issue from "@/api/issue";
export default {
  name: "detail-ssq",
  props: ["project", "tickets", "type", "bonus_sub_tax"],
  data() {
    return {
      actualTickets: [],
      actualTicketsDetail: [],
      childIndex: 0,
      prizeCodeS: [],
    };
  },
  created() {
    this.handleFormatArrSelected(this.project.bet_items, "father");
    this.handleFormatArrSelected(this.tickets, "child");
    this.handleActualTickets(this.tickets);
    this.handleJudgeLast();
  },
  methods: {
    handleToUSerDetail(val) {
      this.$router.push({
        name: "account-book-customer-detail",
        query: {
          user_id: val,
        },
      });
    },
    handleCalcCount() {
      return this.actualTicketsDetail
        .map((o) => {
          return o.count;
        })
        .reduce((x, y) => x + y, 0);
    },
    handleActualTickets(data) {
      data = data.sort((a, b) => {
        return a.index - b.index;
      });

      let type_array = {
        101: [],
        102: [],
        103: [],
      };

      data.forEach((o) => {
        let temp = {
          rd: [],
          rt: [],
          bd: [],
          bt: [],
        };
        o.arrSelected.forEach((o) => {
          temp[o.type].push(o.number);
          temp[o.type].sort();
        });
        type_array[o.play_type].push({
          type: o.play_type,
          value: temp,
          bet_money: o.bet_money,
          project_id: o.project_id,
          bet_codes: o.bet_codes,
        });
      });

      this.actualTickets = [];
      this.actualTicketsDetail = [];
      let actualTickets = [];

      Object.keys(type_array).forEach((o) => {
        let temp = [];
        if (o === "101" || o === "103") {
          type_array[o].forEach((o1, i) => {
            if (i % 5 === 0) {
              temp.push(type_array[o].slice(i, i + 5));
            }
          });
        } else {
          temp = type_array[o].map((o1) => {
            return [o1];
          });
        }
        actualTickets = actualTickets.concat(temp);
      });

      // 判断是否要倍数拆单
      let single_multi = 100;
      let over50Count = Math.ceil(this.project.bet_multi / single_multi);
      let over50Integer = this.project.bet_multi % single_multi === 0;
      actualTickets.forEach((o) => {
        let t = this.project.bet_multi % single_multi;
        let b = o
          .map((o1) => {
            return Number(o1.bet_money);
          })
          .reduce((x, y) => x + y, 0);

        if (over50Integer) {
          this.actualTickets.push(o);
          this.actualTicketsDetail.push({
            count: over50Count,
            bet_multi: single_multi,
            bet_money: b,
          });
        } else if (over50Count > 1) {
          var i = 0;
          while (i < 2) {
            if (i === 0) {
              this.actualTicketsDetail.push({
                count: parseInt(over50Count) - 1,
                bet_multi: single_multi,
                bet_money: b * single_multi,
              });
            } else {
              this.actualTicketsDetail.push({
                count: 1,
                bet_multi: t,
                bet_money: b * t,
              });
            }
            this.actualTickets.push(o);
            i++;
          }
        } else {
          this.actualTickets.push(o);
          this.actualTicketsDetail.push({
            count: 1,
            bet_multi: t,
            bet_money: b * t,
          });
        }
      });
    },
    handleIsSelected(type, number) {
      if (type === "rt" || type === "rd") {
        return this.prizeCodeS[0].prize.slice(0, 6).includes(number);
      } else {
        return this.prizeCodeS[0].prize.slice(6, 7).includes(number);
      }
    },
    handleChange(val) {
      this.childIndex = val;
      this.handleJudgeLast();
      this.handleScrollTo();
    },
    handleJudgeLast() {
      if (this.childIndex + 1 === this.actualTickets.length) {
        this.$emit("handleIsLast", true);
      } else {
        this.$emit("handleIsLast", false);
      }
    },
    handleScrollTo() {
      // let order_list_height =
      //   document.getElementsByClassName("order-list")[0].clientHeight + 10;
      document.getElementsByClassName("item-detail")[0].scrollTop = 0;
    },
    handleLast() {
      if (this.childIndex === 0) {
        this.$toast("当前第一张票");
        return;
      }
      this.$refs.swipe.prev();
    },
    handleNext() {
      if (this.childIndex + 1 === this.actualTickets.length) {
        this.$toast("当前最后一张票");
        return;
      }
      this.$refs.swipe.next();
    },
    handlePrint() {
      let ticket = this.actualTickets[this.childIndex];
      let obj = {
        sum: this.actualTickets.length,
        childIndex: this.childIndex,
        project_id: this.project.project_id,
        bet_codes: "",
        bet_money: this.actualTicketsDetail[this.childIndex].bet_money,
        type: this.project.play_type,
        play_type: "",
      };
      let code = [];
      ticket.forEach((res) => {
        obj.project_id = res.project_id;
        obj.play_type = res.type;
        obj.bet_multi = this.actualTicketsDetail[this.childIndex].bet_multi;
        // obj.bet_money += parseInt(res.bet_money);
        code.push(res.bet_codes);
      });
      console.log("obj", obj);
      obj.bet_codes = code.join("&");
      this.$emit("handlePrint", obj);
    },
    handleFormatArrSelected(obj, type) {
      let prizes = {};
      obj.forEach((item) => {
        prizes[item.number] = item.type;
        item.arrSelected = [];
        let codes = item.bet_codes.split("#");

        let arr = [
          {
            key: "rd",
            value: codes[0].indexOf("$") > 0 ? codes[0].split("$")[0] : "",
          },
          {
            key: "rt",
            value:
              codes[0].indexOf("$") > 0 ? codes[0].split("$")[1] : codes[0],
          },
          {
            key: "bd",
            value: codes[1].indexOf("$") > 0 ? codes[1].split("$")[0] : "",
          },
          {
            key: "bt",
            value:
              codes[1].indexOf("$") > 0 ? codes[1].split("$")[1] : codes[1],
          },
        ];

        arr.forEach((o, i) => {
          if (arr[i].value) {
            let res = arr[i].value.split(",").map((o1) => {
              return {
                number: o1,
                type: arr[i].key,
              };
            });
            item.arrSelected = item.arrSelected.concat(res);
          }
        });
      });

      if (type === "father") {
        this.prizeCodeS = [];
        for (const item in prizes) {
          issue
            .get({ type: prizes[item], number: item })
            .then((res) => {
              if (res.results.prize_code) {
                this.prizeCodeS.push({
                  number: res.results.number,
                  prize: res.results.prize_code.replace("#", ",").split(","),
                });
              }
            })
            .catch(() => {});
        }
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
#detail-ssq {
  .info-sticky{
    background:#f0f0f0;
    padding-top: 10px;
    .info {
      padding: 10px;
      background: #ffffff;
      font-size: 14px;
      color: #888888;

      .bonus {
        color: #f00;
      }

      div {
        padding: 2px;
      }
    }
  }

  .order-list {
    font-size: 12px;

    li {
      background: #fff;
      margin: 0px auto;
      border-radius: 2px;
      align-items: center;
      padding: 10px;

      .detail {
        display: flex;
        flex-wrap: wrap;
        >div {
          height: 40px;
        }

        .ball {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          background: #ffffff;
          display: flex;
          justify-content: center;
          align-items: center;
          float: left;
          margin-left: 5px;
          font-size: 14px;
          border: 1px solid #ddd;
          position: relative;
          margin-top: 5px;
          label {
            position: absolute;
            bottom: -17px;
            color: #f00;
          }
        }

        .ball-number {
          margin 0 3px;
        }

        .red {
          background: red;
          color: #ffffff;
          border: none;
        }

        .blue {
          background: #5a8ef5;
          color: #ffffff;
          border: none;
        }

        .red.dan {
          background: #f28282;
          color: #ffffff;
          border: none;
        }

        .blue.dan {
          background: #89adf3;
          color: #ffffff;
          border: none;
        }

        .dan-text {
          transform: scale(0.7);
        }
      }

      .info {
        color: #666;
      }
    }
  }

  .child {
    .body {
      position: relative;
      background: #fff;
    }
    .child-content {
      position: relative;
      background: #fff;

      .child-tag {
        position: absolute;
        top: 10px;
        left: 0;
        right: 0;
        text-align: center;
        color: red;

        span {
          font-weight: bold;
          font-size: 20px;
        }
      }

      ul {
        background: #fff;
        padding-top: 10px;
        padding: 42px 35px 0;

        li {
          margin-bottom: 0px !important;
          font-size: 14px;
        }
      }
    }

    .nav {
      display: flex;
      align-items: center;
      height: 50px;
      justify-content: space-evenly;
      background: #fff;
      .arrow-left {
        position: absolute;
        top: 50%;
        left: 10px;
        margin-top: -12px;
      }
      .arrow-right {
        position: absolute;
        top: 50%;
        right: 10px;
        margin-top: -12px;
      }
    }
  }
}
</style>
